<template>
    <main class="w-full mt-0 pl-3 pr-2 pb-0 pt-3 flex flex-col justify-start items-center gap-3">
        <div class="flex flex-col gap-3 w-full xl:w-10/12 justify-center items-center">
            <Card class="w-full" :pt="{ content: { class: 'p-0' } }">
                <template #title>
                    <span>Zgłaszanie problemu</span>
                    <Divider class="!my-3" />
                </template>
                <template #content>
                    <form @submit.prevent="handleSubmit">
                        <div v-for="(item, index) in data">
                            <FormField v-model="item.val" v-bind="item" :ref="references[item.uuid]" />
                        </div>

                        <div class="w-full flex justify-center items-center mt-7">
                            <Button @click="handleSubmit()">Zgłoś problem</Button>
                        </div>
                    </form>
                </template>
            </Card>
        </div>
    </main>
</template>

<script setup>
import { ref, onMounted, inject } from 'vue';
import { useRouter } from 'vue-router';
import { format } from 'date-fns';
import { userdataStore } from '@/stores/userData'
import Card from 'primevue/card';
import Divider from 'primevue/divider';
import Button from 'primevue/button';
import FormField from '@/components/forms/FormField.vue';

const userdata = userdataStore();
const showToast = inject('showToast');
const references = Array.from({ length: 1 }, (_, i) => ({}[i + ""] = ref()));
const router = useRouter();

const data = ref([
    {
        uuid: '0',
        component: "TextArea",
        label: "Opisz problem:",
        errorLabels: ['Opis jest wymagany'],
        innerInputProps: {
            autoResize: true,
            rows: 5
        },
        conditions: [{
            type: "required",
        },],
        placeholder: "Np.: Nie mogę podać danych we wniosku.",
        val: null
    },

])

const handleSubmit = async () => {

    let status = true;

    for (const reference of references.values()) {
        if (!reference.value[0].validate()) {
            status = false;
        }
    }

    if (status) {
        let result = await fetch('/api/problem', {
            body: JSON.stringify({
                user: userdata.username,
                userEmail: userdata.email,
                date: format(new Date(), 'dd-MM-yyyy'),
                time: format(new Date(), 'HH:mm'),
                description: data.value[0].val
            }),
        }).then((r) => r.json());

        showToast('success', 'Zgłoszono problem', "Postaramy się go rozwiązać naszybciej jak to możliwe.");
        router.go(-1);
    } else {
        showToast('error', 'Błąd', "Wypełnij wszystkie pola.");
    }

}
</script>