<template>
    <main class="w-100 mt-0 pl-3 pr-2 pb-0 flex flex-col justify-start items-center gap-3">
        <div class="flex flex-col gap-3  w-full xl:w-8/12 justify-center items-center">
            <Card v-if="!loading" class="w-full" :pt="{ content: { class: 'p-0' } }">
                <template #header>
                    <ProgressBar v-if="generating" mode="indeterminate" style="height: 6px"></ProgressBar>
                </template>
                <template #title>
                    <div class="flex flex-row justify-between">
                        <span>Wniosek o upadłość konsumencką</span>
                        <StatusTag :severityMap="severityMap" :msgMap="msgMap" :status="status" />
                        <!-- sprawdzanie danych wygenerowanych automatycznie. -->
                        <!-- gotowy do akceptacji uzasadnienia - stan -->
                    </div>
                    <Divider class="!my-3" />
                </template>
                <template #content>
                    <div class="flex flex-col">
                        <!-- <span class="w-full text-center">Wypełniono:</span> -->
                        <p class="m-0 px-3 flex flex-row justify-between items-center">
                            Wypełnienie wniosku:
                            <Knob v-model="progress" readonly :size="130" :strokeWidth="6" valueTemplate="{value}%"
                                :pt="{ text: { class: 'text-sm' } }" />
                        </p>
                        <Divider v-if="status != 'verification_needed' && status != 'transaction_in_progress'"
                            class="!my-3 !mb-5" />
                        <div class="flex flex-col w-full justify-center items-center text">
                            <div v-for="btn in btnData" class="w-fit">
                                <Button :key="btn.name" :label="btn.name" :disabled="disabled"
                                    v-if="status == btn.status" raised class="w-fit" @click="btn.action()" />
                            </div>
                            <span v-if="generating" class="mt-3 text-[0.75rem] text-surface-400">Generowanie pliku
                                PDF...</span>
                        </div>
                    </div>
                </template>
            </Card>
            <div v-if="loading" class="w-full">
                <div
                    class="rounded shadow p-6 bg-surface-0 dark:bg-surface-900 flex justify-center items-center flex-col mb-4">
                    <div class="flex w-full">
                        <div class="flex flex-row w-full justify-between">
                            <Skeleton width="25rem" height="70px" class="mb-2"></Skeleton>
                            <Skeleton width="10rem" height="70px"></Skeleton>
                        </div>
                    </div>
                    <Divider />
                    <div class="flex w-full">
                        <div class="flex flex-row w-full justify-between items-center">
                            <Skeleton width="10rem" height="40px" class="mb-2"></Skeleton>
                            <Skeleton width="7rem" height="7rem"></Skeleton>
                        </div>
                    </div>
                    <Divider />
                    <Skeleton width="20%" height="70px"></Skeleton>
                </div>
            </div>
            <Card v-if="status == 'ready'" class="w-full"
                :pt="{ body: { class: 'p-5 pb-3' }, content: { class: 'p-0' } }">
                <template #title>
                    Instrukcja
                    <Divider class="!my-3" />
                </template>
                <template #content>
                    <div>
                        Ściśle wykonaj poniższą instrukcję <b>BEZ ZMIANY</b> kolejności kroków.
                        <ol class="list-decimal list-outside pl-4 text-justify">
                            <li>Wydrukuj pobrany wniosek.</li>
                            <li>Przeczytaj uważnie wydrukowany wniosek.</li>
                            <li>Jeżeli we wniosku znajdują się błędne dane lub błędne informacje, skontaktuj się
                                z Nami mailowo <a style="color: var(--p-primary-500);text-decoration:underline;"
                                    href="https://mail.google.com/mail/?view=cm&fs=1&to=biuro.arkancelaria@gmail.com"
                                    target="_blank">biuro.arkancelaria@gmail.com</a> lub telefonicznie <b>534 123
                                    123</b>.
                                Jeżeli wszystkie informacje oraz dane są prawidłowe i akceptujesz wniosek przejdź do
                                kolejnego kroku.
                            </li>
                            <li>Na pierwszej stronie wniosku w górnym prawym rogu przyklej znak opłaty sądowej o
                                wartości 30 zł. Znak opłaty sądowej możesz kupić online <a target="_blank"
                                    style="color: var(--p-primary-500);text-decoration:underline;"
                                    href="https://oplaty.ms.gov.pl/znaki-oplaty-sadowej/zakup">tutaj</a>.
                            </li>
                            <li>
                                Po przyklejeniu opłaty sądowej wykonaj kopię (ksero) całego wniosku.
                            </li>
                            <li>Tak przygotowane dwa egzemplarze wniosku wraz z wymienionymi we wniosku
                                załącznikami,
                                które w dwóch egzemplarzach przygotowywałeś/łaś podczas wypełniania wniosku,
                                wyślij listem poleconym do sądu wskazanego we wniosku.
                            </li>
                            <li>Jeżeli chcesz abyśmy pomagali Ci w trakcie postępowania upadłościowego zgłoś się do nas
                                telefonicznie <b>534 123 123</b> lub mailowo <a
                                    style="color: var(--p-primary-500);text-decoration:underline;"
                                    href="https://mail.google.com/mail/?view=cm&fs=1&to=biuro.arkancelaria@gmail.com"
                                    target="_blank">biuro.arkancelaria@gmail.com</a>, po wysłaniu wniosku.
                            </li>
                        </ol>
                    </div>
                </template>
            </Card>
            <!-- <Card v-if="true" class="w-full border-blue-500 border-[3px]"
                :pt="{ body: { class: 'p-5 pb-3' }, content: { class: 'p-0' } }">
                <template #title>
                    Debug mode
                    <Divider class="!my-3" />
                </template>
                <template #content>
                    <div class="flex gap-3 justify-between flex-wrap">
                        <Button class="mr-3" @click="resetForm">Zresetuj formularz</Button>
                        <Button class="mr-3" @click="resetStatus">Zresetuj status (nie usuwa danych wniosku)</Button>
                        <Button @click="verifyForm">Zweryfikuj formularz</Button>
                    </div>

                </template>
            </Card> -->
            <Dialog v-model:visible="userAgreement" modal header="Regulamin" :style="{ width: '60rem' }">
                <div class="w-full flex flex-col items-center justify-center">
                    <div ref="scrollPanel" @scroll="handleScroll"
                        class="w-full h-[15rem] border-2 p-5 rounded-lg overflow-y-scroll">
                        <agreement />
                    </div>


                    <div class="flex items-center gap-2 my-5">
                        <!-- :disabled="!scrolledToBottom" -->
                        <Checkbox  v-model="userAgreementConfirmation" binary
                            inputId="agreement" name="confirmation" />
                            <!-- :class='{ "text-surface-300": !scrolledToBottom }' -->
                        <label  class="cursor-pointer"
                            for="agreement">
                            Zapoznałem/łam się i akceptuję Regulamin oraz Politykę prywatności. </label>
                    </div>

                    <Button :disabled="!userAgreementConfirmation || waitingForCreation"
                        @click="gotoPayment">Dalej</Button>
                    <ProgressBar v-if="userAgreementConfirmation && waitingForCreation" mode="indeterminate"
                        style="height: 6px" class="w-3/12 mt-3"></ProgressBar>
                </div>
            </Dialog>
            <Dialog v-model:visible="visiblePayment" modal header="Płatność" class="w-[45rem]">
                <p class="pb-3 text-justify">
                    Dostęp do platformy jest odpłatny.
                    Jeśli będziesz postępować zgodnie z instrukcjami, przygotowanie wniosku nie powinno zająć Ci dłużej niż 3 miesiące.
                    Subskrypcja nie odnawia się automatycznie – po upływie roku nie naliczymy Ci żadnych dodatkowych kosztów bez Twojej świadomej decyzji.
                </p>
                <p class="pb-5">Cena rocznej subskrypcji: 2000zł</p>
                <StripePayment class="w-[30rem] mx-auto" @payment-status="handlePaymentStatus" @close-window="handleCloseWindow"></StripePayment>
            </Dialog>
        </div>

    </main>
</template>

<script setup>
import { onMounted, ref, inject, onUnmounted, watch } from 'vue'
import { useRouter } from 'vue-router'
import { langObject } from '@/languages/pl.js';
import { userdataStore } from '@/stores/userData'

import Card from 'primevue/card'
import Dialog from 'primevue/dialog'
import Skeleton from 'primevue/skeleton'

import Checkbox from 'primevue/checkbox'
import Tag from 'primevue/tag'
import ProgressBar from 'primevue/progressbar'
import Button from 'primevue/button'
import Divider from 'primevue/divider'
import Knob from 'primevue/knob'

import StripePayment from '@/components/Stripe/StripePayment.vue'
import StatusTag from '@/components/forms/StatusTag.vue';
import agreement from '@/components/content/agreement.vue';

const router = useRouter();
const showToast = inject('showToast');
const userdata = userdataStore();

let type = 0;
const progress = ref(10);
const status = ref('requires_completion');
const userAgreement = ref(false);
const userAgreementConfirmation = ref(false);
const waitingForCreation = ref(false);
const visiblePayment = ref(false);
const generating = ref(false);
const disabled = ref(false);
const scrollPanel = ref(null);
const scrolledToBottom = ref(false);
const loading = ref(true);

const severityMap = new Map([
    ['no_access', 'danger'],
    ['transaction_in_progress', 'secondary'],
    ['requires_completion', 'secondary'],
    ['verification_needed', 'warn'],
    ['ready', 'success'],
]);

const msgMap = new Map([
    ['no_access', 'Brak dostępu'],
    ['transaction_in_progress', 'Oczekiwanie na zakończenie płatności'],
    ['requires_completion', 'Wymaga uzupełnienia'],
    ['verification_needed', 'W trakcie przygotowania'],
    ['ready', 'Gotowy do pobrania'],
]);


if (type == 0) {
    progress.value = 0;
    status.value = 'no_access';
} else if (type == 1) {
    progress.value = 0;
    status.value = 'transaction_in_progress';
} else if (type == 2) {
    disabled.value = true;
    progress.value = 10;
    status.value = 'requires_completion';
} else if (type == 3) {
    progress.value = 100;
    status.value = 'verification_needed';
} else if (type == 4) {
    progress.value = 100;
    status.value = 'ready';
}

let id = '';

async function getPDF() {
    generating.value = true;
    disabled.value = true;
    let response = await fetch('/api/export-pdf', {
        body: JSON.stringify({
            objectUUID: id,
            functionalityUUID: "c98b6831-bc1a-4d84-b78a-fbb1188ca440",
        })
    })
    // response.headers.forEach((value, name) => {
    //     console.log("🚀 ~ getPDF ~ response:\x1B[34mutil.inspect(, false, 10, true)\x1B[0m", name, value)
    // })

    if (response.ok) {
        if (response.headers.get('Content-Type') === 'application/pdf') {
            const data = await response.blob();

            const link = document.createElement('a');
            link.href = URL.createObjectURL(data);
            const filename = response.headers.get('Content-Disposition')?.split('filename=')?.[1]?.trim() || 'example.pdf';
            link.download = filename;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            showToast('error', 'Błąd', langObject.error_unknown);
        }
    }

    // await new Promise(resolve => setTimeout(resolve, 5000));
    generating.value = false;
    disabled.value = false;
}

const btnData = ref([
    {
        name: 'Wykup dostęp',
        status: 'no_access',
        action: () => {
            userAgreement.value = true;
        }
    },
    {
        name: 'Uzupełnij wniosek',
        status: 'requires_completion',
        action: () => {
            router.push({ path: `/wniosek/${id}` });
        }
    },
    {
        name: 'Pobierz wniosek',
        status: 'ready',
        action: () => {
            getPDF();
        }
    },
]);

const gotoPayment = async () => {
    while (id == '') {
        waitingForCreation.value = true;
        await new Promise(resolve => setTimeout(resolve, 500));
    }

    waitingForCreation.value = false;
    userAgreement.value = false;
    visiblePayment.value = true;

    try {
        const response = await fetch('/api/objectModify', {
            body: JSON.stringify({
                object: {
                    root: {
                        labels: [
                            "SimpleForm"
                        ],
                        properties: {
                            "agreement": true
                        },
                        uuid: id,
                        list_prop: {
                            dataType: "Map",
                            value: []
                        },
                        orgUUID: "0a754dfd-5c83-4356-baff-1b687aaf3fd1",
                        mod_parent: 0,
                        mod_order: 0,
                        mod_org: 0,
                        mod: 2
                    },
                    formUUID: "169fe196-b2de-4d7d-8ebe-d1217251717a",
                    objects: {
                        dataType: "Map",
                        value: []
                    },
                    structure: {
                        dataType: "Map",
                        value: []
                    },
                    formEditDate: 1910959199504
                }
            })
        });

        if (response.ok) {
            const data = await response.json();
            // console.log("🚀 ~ save ~ data:\x1B[34mutil.inspect(, false, 10, true)\x1B[0m", data)
            if (data.success == 1) {
                // showToast('success', 'Sukces', data.result);
            }
            else {
                showToast('error', 'Błąd', data.result);
            }
        }
    } catch (error) {
        showToast('error', 'Błąd', data.result);
    }
};

const handleScroll = () => {
    const element = scrollPanel.value;
    const isAtBottom = element.scrollTop + element.clientHeight >= element.scrollHeight;

    if (isAtBottom) {
        scrolledToBottom.value = true;

    }
};

// async function resetForm() {
//     generating.value = true;
//     disabled.value = true;
//     try {
//         const response = await fetch('/api/objectModify', {
//             body: JSON.stringify({
//                 object: {
//                     root: {
//                         labels: [
//                             "SimpleForm"
//                         ],
//                         properties: {
//                             "name": "",
//                             "surname": "",
//                             "phone": "",
//                             "pesel": "",
//                             "data": "",
//                             "objects": "",
//                             "description": "",
//                             "status": "requires_completion",
//                             "statusCourt": "never_matched",
//                             "maxActive": 0
//                         },
//                         uuid: id,
//                         list_prop: {
//                             dataType: "Map",
//                             value: [

//                             ]
//                         },
//                         orgUUID: "0a754dfd-5c83-4356-baff-1b687aaf3fd1",
//                         mod_parent: 0,
//                         mod_order: 0,
//                         mod_org: 0,
//                         mod: 2
//                     },
//                     formUUID: "169fe196-b2de-4d7d-8ebe-d1217251717a",
//                     objects: {
//                         dataType: "Map",
//                         value: []
//                     },
//                     structure: {
//                         dataType: "Map",
//                         value: []
//                     },
//                     formEditDate: 1810959199504
//                 }
//             })
//         });

//         if (response.ok) {
//             const data = await response.json();
//             // console.log("🚀 ~ save ~ data:\x1B[34mutil.inspect(, false, 10, true)\x1B[0m", data)
//             if (data.success == 1) {
//                 // showToast('success', 'Sukces', data.result);
//             }
//             else {
//                 showToast('error', 'Błąd', data.result);
//             }
//         }
//     } catch (error) {
//         showToast('error', 'Błąd', data.result);
//     }
//     generating.value = false;
//     disabled.value = false;
// }

// async function verifyForm() {
//     generating.value = true;
//     disabled.value = true;
//     try {
//         const response = await fetch('/api/objectModify', {
//             body: JSON.stringify({
//                 object: {
//                     root: {
//                         labels: [
//                             "SimpleForm"
//                         ],
//                         properties: {
//                             "status": "ready"
//                         },
//                         uuid: id,
//                         list_prop: {
//                             dataType: "Map",
//                             value: [

//                             ]
//                         },
//                         orgUUID: "0a754dfd-5c83-4356-baff-1b687aaf3fd1",
//                         mod_parent: 0,
//                         mod_order: 0,
//                         mod_org: 0,
//                         mod: 2
//                     },
//                     formUUID: "169fe196-b2de-4d7d-8ebe-d1217251717a",
//                     objects: {
//                         dataType: "Map",
//                         value: []
//                     },
//                     structure: {
//                         dataType: "Map",
//                         value: []
//                     },
//                     formEditDate: 1810959199504
//                 }
//             })
//         });

//         if (response.ok) {
//             const data = await response.json();
//             // console.log("🚀 ~ save ~ data:\x1B[34mutil.inspect(, false, 10, true)\x1B[0m", data)
//             if (data.success == 1) {
//                 // showToast('success', 'Sukces', data.result);
//             }
//             else {
//                 showToast('error', 'Błąd', data.result);
//             }
//         }
//     } catch (error) {
//         showToast('error', 'Błąd', data.result);
//     }
//     generating.value = false;
//     disabled.value = false;
// }

// async function resetStatus() {
//     generating.value = true;
//     disabled.value = true;
//     try {
//         const response = await fetch('/api/objectModify', {
//             body: JSON.stringify({
//                 object: {
//                     root: {
//                         labels: [
//                             "SimpleForm"
//                         ],
//                         properties: {
//                             "status": "requires_completion"
//                         },
//                         uuid: id,
//                         list_prop: {
//                             dataType: "Map",
//                             value: [

//                             ]
//                         },
//                         orgUUID: "0a754dfd-5c83-4356-baff-1b687aaf3fd1",
//                         mod_parent: 0,
//                         mod_order: 0,
//                         mod_org: 0,
//                         mod: 2
//                     },
//                     formUUID: "169fe196-b2de-4d7d-8ebe-d1217251717a",
//                     objects: {
//                         dataType: "Map",
//                         value: []
//                     },
//                     structure: {
//                         dataType: "Map",
//                         value: []
//                     },
//                     formEditDate: 1810959199504
//                 }
//             })
//         });

//         if (response.ok) {
//             const data = await response.json();
//             // console.log("🚀 ~ save ~ data:\x1B[34mutil.inspect(, false, 10, true)\x1B[0m", data)
//             if (data.success == 1) {
//                 // showToast('success', 'Sukces', data.result);
//             }
//             else {
//                 showToast('error', 'Błąd', data.result);
//             }
//         }
//     } catch (error) {
//         showToast('error', 'Błąd', data.result);
//     }
//     generating.value = false;
//     disabled.value = false;
// }

async function getWniosek() {
    try {
        const response = await fetch('/api/getListObjects', {
            body: JSON.stringify({
                listUUID: "f6f22d5b-ec4a-4ba9-b2ce-8a3d84a1f933",
                propertyMap: {
                    name: ".*",
                    surname: ".*",
                    pesel: ".*",
                    phone: ".*",
                    email: ".*",
                    uuid: ".*",
                    statusCourt: ".*",
                    maxActive: ".*",
                    status: ".*"
                }
            })
        });

        if (response.ok) {
            const data = await response.json();

            if (data.success == 1) {
                if (data.result.objects.length == 0) {
                    await createWniosek();
                } else {
                    id = data.result.objects[0].uuid;

                    if (!generating.value) {
                        disabled.value = false;
                        progress.value = Math.round(data.result.objects[0].maxActive / 284 * 100);
                        status.value = data.result.objects[0].status;
                    }
                }
            }
            else if (data.success == 2) {
                await createWniosek();
                // showToast('error', 'Błąd', data.result);
            }
        }

    } catch (error) {
        console.error(error);
    }
}

let creationLock = false;
const createWniosek = async () => {
    if (creationLock) return;
    else creationLock = true;
    console.log("🚀 ~ createWniosek ~ userdata.defaultOrg:\x1B[34mutil.inspect(, false, 10, true)\x1B[0m", userdata.defaultOrg)

    try {
        const responseCreate = await fetch('/api/objectModify', {
            body: JSON.stringify({
                object: {
                    root: {
                        labels: [
                            "SimpleForm"
                        ],
                        properties: {
                            "name": userdata.name,
                            "surname": userdata.surname,
                            "email": userdata.email,
                            "phone": "",
                            "pesel": "",
                            "data": "",
                            "objects": "",
                            "description": "",
                            "status": "no_access",
                            "statusCourt": "never_matched",
                            "maxActive": 0
                        },
                        uuid: 1,
                        list_prop: {
                            dataType: "Map",
                            value: []
                        },
                        orgUUID: userdata.defaultOrg,
                        mod_parent: 0,
                        mod_order: 0,
                        mod_org: 0,
                        mod: 1
                    },
                    formUUID: "169fe196-b2de-4d7d-8ebe-d1217251717a",
                    objects: {
                        dataType: "Map",
                        value: []
                    },
                    structure: {
                        dataType: "Map",
                        value: []
                    },
                    formEditDate: 1910959199504
                }
            })
                        
        });

        if (responseCreate.ok) {
            const data = await responseCreate.json();
            // console.log("🚀 ~ save ~ data:\x1B[34mutil.inspect(, false, 10, true)\x1B[0m", data)
            if (data.success == 1) {
                // showToast('success', 'Sukces', data.result);
            }
            else {
                showToast('error', 'Błąd', data.result);
            }
        }
    } catch (error) {
        showToast('error', 'Błąd', error);
    }
};

fetch('/api/validatePaymentAccess').then(() => {
    getWniosek().then(() => {
        loading.value = false;
        if (router.options.history.state.back == '/ankieta' && status.value == "no_access") {
            userAgreement.value = true;
        }
    });
})

let myInterval = setInterval(getWniosek, 5000);

// onMounted(() => {
//     if (router.options.history.state.back == '/ankieta') {
//         userAgreement.value = true;
//     }
// })

onUnmounted(() => {
    clearInterval(myInterval);
})

const handlePaymentStatus = (payload) => {
    let status = payload.status;

    switch (status) {
        case '1':
            showToast('success', 'Płatność zaakceptowano', langObject.error_unknown);
            break;
        case '2':
            showToast('error', 'Błąd', langObject.error_unknown);
            console.error(payload.error);
            break;
        default:
            showToast('error', 'Błąd', langObject.error_unknown);
            break;

    }
};

const handleCloseWindow = () => {
    visiblePayment.value = false;
};

</script>
