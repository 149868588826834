<template>
    <div>
        Ostatnia aktualizacja: 25/11/2024

        PRZED ROZPOCZĘCIEM KORZYSTANIA Z USŁUG (ZDEFINIOWANYCH PONIŻEJ) NALEŻY PRZECZYTAĆ NINIEJSZE WARUNKI (WARUNKI lub
        UMOWĘ).

        NINIEJSZE WARUNKI NAKŁADAJĄ NA UŻYTKOWNIKA OBOWIĄZEK ROZSTRZYGANIA WSZELKICH SPORÓW Z NAMI W DRODZE ARBITRAŻU,
        A PONADTO UŻYTKOWNIK ZRZEKA SIĘ PRAWA DO DOCHODZENIA ROSZCZEŃ PRZECIWKO NAM W SĄDZIE,
        DO ROZPATRYWANIA SPORÓW PRZEZ SĘDZIEGO LUB ŁAWĘ PRZYSIĘGŁYCH ORAZ DO DOCHODZENIA ROSZCZEŃ PRZECIWKO NAM W TRYBIE
        POZWU ZBIOROWEGO.
        Aby dowiedzieć się, jak zrezygnować z obowiązkowego arbitrażu, należy zapoznać się z sekcją 7(F) (Odstąpienie).

        NINIEJSZE WARUNKI OBEJMUJĄ RÓWNIEŻ ZRZECZENIE SIĘ PRZEZ UŻYTKOWNIKA PRAWA DO WNIESIENIA PRZECIWKO NAM POZWU
        ZBIOROWEGO
        ORAZ ODSTĄPIENIE OD WSZELKICH ROSZCZEŃ WOBEC NAS, KTÓRE MOGĄ WYNIKNĄĆ Z KORZYSTANIA PRZEZ UŻYTKOWNIKA Z NASZYCH
        USŁUG.

        Strona internetowa upadlosc-ar-expert.pl, („Strona”) wraz ze wszystkimi powiązanymi treściami, funkcjami i
        usługami
        są dostarczane przez Upadłość Konsumencka - Kancelaria Adam Rogalski zwaną dalej "Administratorem" lub
        "Usługodawcą".

        Nabywając subskrypcję serwisów, potwierdzasz, że przeczytałeś i zrozumiałeś
        niniejszy regulamin oraz zgadzasz się na jego przestrzeganie.

        W niniejszym regulaminie termin użytkownik odnosi się do użytkowników (zgodnie z definicją poniżej)
        korzystających z naszych serwisów. Osoba dorosła to osoba pełnoletnia zgodnie z prawem obowiązującym w miejscu
        jej zamieszkania, która może zgodnie
        z prawem zawrzeć umowę. Nabywając subskrypcję, użytkownik oświadcza, że jest osobą dorosłą.

        Niniejszy dokument stanowi prawnie wiążącą umowę między użytkownikiem a nami, także opsiuje i stanowi umowę
        subskrypcyjną. Ponadto nasza polityka prywatności
        opisuje, w jaki sposób gromadzimy, przetwarzamy i wykorzystujemy informacje przekazywane nam przez użytkownika
        podczas korzystania z serwisu.

        Jeśli korzystasz z serwisu lub zakładasz
        u nas konto, potwierdzasz, że jesteś obywatelem państwa Polskiego oraz jesteś pełnoletni, a my możemy w
        uzasadniony sposób na tym polegać.

        Niniejszy regulamin i nasza polityka prywatności mogą być od czasu do czasu aktualizowane lub
        modyfikowane. Dalsze korzystanie z naszych usług jest jednoznaczne z wyrażeniem
        zgody na wszelkie zmiany w niniejszych umowach i zasadach.
        W przypadku wystąpienia zmiany w niniejszych umowach i zasadach, zobowiązujemy się do poinformowania użytkownika
        mailowo o wystąpieniu zmiany.

        Jednocześnie w przypadku chęci wyrażeniu sprzeciwu na nowy regulamin - użytkownik jest zobowiązany do
        poinformowania nas mailowo stosowną wiadomością na adres:
        biuro.arkancelaria@gmail.com

        Podsumowanie najważniejszych informacji
        Należy zapoznać się z całym tekstem niniejszego regulaminu, a także z naszą globalną polityką prywatności, ale
        oto kilka najważniejszych kwestii:

        Każde konto jest przypisane do jednego użytkownika i jest przeznaczone wyłącznie do osobistego, niekomercyjnego
        użytku.

        WYRAŻASZ ZGODĘ NA ROZSTRZYGANIE SPORÓW ZA POMOCĄ ARBITRAŻU, A NIE NA DRODZE POSTĘPOWANIA SĄDOWEGO, jak określono
        w sekcji 7 (Arbitraż i rozstrzyganie sporów).

        Użytkownik korzysta z serwisów W STANIE, W JAKIM SĄ, bez gwarancji i nie będziemy ponosić żadnej
        odpowiedzialności z tego tytułu, jak określono w sekcji 8
        (Wyłączenie odpowiedzialności z tytułu oświadczeń i gwarancji) i sekcji 9 (Ograniczenie odpowiedzialności).

        Spis treści

        1. Konta, hasła i płatności

        2. Własność serwisów i licencje

        3. Treści przesyłane przez użytkowników

        4. Ograniczenia dotyczące korzystania z serwisów i treści

        5. Łączenie z naszymi serwisami

        6. Prawo właściwe

        7. Arbitraż i rozstrzyganie sporów

        8. Wyłączenie odpowiedzialności z tytułu oświadczeń i gwarancji

        9. Ograniczenie odpowiedzialności

        10. Odszkodowanie

        11. Polityka dotycząca naruszeń i procedura zgłaszania

        12. Funkcje bezprzewodowe

        13. Przesyłanie informacji zwrotnych

        14. Postanowienia ogólne

        1. Konta, hasła i płatności

        A. Rodzaje kont

        Użytkownik może korzystać z części naszych usług za darmo, a także zdecydować się na subskrypcję, aby zostać
        członkiem. Użytkownik nie musi być członkiem, aby korzystać z naszych usług. Członek, który posiada
        subskrypcję, może korzystać z szerszego zakresu naszych usług, w zależności od obowiązujących
        warunków subskrypcji konta. Postanowienia niniejszego regulaminu mają zastosowanie do wszystkich rodzajów konta.

        Konta członkowskie

        Konta członkowskie to konta oferowane przez nas za opłatą subskrybcyjną, które pozwalają na korzystanie z
        rozszerzonego zakresu funkcjonalności naszych usług. Konto członkowskie jest dostępne do użytku przez jednego
        użytkownika.


        B. Hasła i dostęp do konta

        Każde konto ma jednego wyznaczonego użytkownika, który musi być osobą pełnoletnią zgodnie z prawem obowiązującym
        w miejscu zamieszkania i zdolną do zawarcia umowy zgodnie z prawem. Użytkownik jest odpowiedzialny za zachowanie
        poufności swojego hasła i informacji o koncie. Użytkownik wyraża zgodę na to, że: (a) użytkownik poda kompletne
        i dokładne informacje rejestracyjne o sobie i nie upoważni osób trzecich do dostępu do swojego konta, oraz
        będzie aktualizować informacje o swoim koncie; (b) użytkownik ponosi wyłączną odpowiedzialność za wszystkie
        działania, które mają miejsce na jego koncie; (c) użytkownik niezwłocznie powiadomi nas o każdym
        nieautoryzowanym użyciu konta; (d) nie ponosimy żadnej odpowiedzialności za jakiekolwiek straty, które
        użytkownik może ponieść w wyniku nieautoryzowanego użycia konta i hasła; oraz (e) użytkownik nie sprzeda, nie
        przeniesie ani nie sceduje swojego konta ani żadnych praw do konta. Jeśli dowiemy się, że nieuprawniony
        użytkownik utworzył konto, możemy poprosić o potwierdzenie statusu użytkownika lub dezaktywować konto bez
        powiadomienia nieuprawnionego użytkownika.

        C. Rejestracja konta

        Użytkownik musi zarejestrować konto poprzez: (a) podanie prawdziwych, dokładnych, aktualnych i kompletnych
        informacji o sobie zgodnie z naszym formularzem rejestracyjnym; oraz (b) niezwłoczne aktualizowanie danych
        rejestracyjnych, aby były one prawdziwe, dokładne, aktualne i kompletne. Jeśli użytkownik poda jakiekolwiek
        informacje, które są nieprawdziwe, niedokładne, nieaktualne lub niekompletne, lub jeśli mamy uzasadnione
        podstawy, aby podejrzewać, że takie informacje są nieprawdziwe, niedokładne, nieaktualne lub niekompletne,
        zawiesimy lub zamkniemy konto użytkownika i odmówimy jakiegokolwiek bieżącego lub przyszłego korzystania z
        serwisu.

        D. Opłaty za konto

        Użytkownik ma możliwość opłacenia subskrypcji udostępniającej system do tworzenia wniosków o upadłość
        konsumencką.
        Wszystkie opłaty są należne zgodnie z warunkami płatności obowiązującymi w momencie, gdy opłata lub
        należność staje się wymagalna.
        Należy pamiętać, że nie zapewniamy ochrony cen ani zwrotów dla istniejących subskrybentów w przypadku obniżenia
        cen lub
        oferty promocyjnej dla nowych subskrybentów. Nieuiszczenie jakichkolwiek opłat może skutkować niemożnością
        uzyskania dostępu do serwisów lub korzystania z nich.

        (i) Upoważnienie do pobierania opłat za usługi

        Musisz użyć karty debetowej lub płatności blik, aby aktywować i korzystać z płatnego konta. Upoważniasz nas do
        pobierania opłat za pomocą mechanizmu płatności, z którego korzystasz podczas rejestracji konta. Ponadto
        ponosisz odpowiedzialność za opłaty (w tym obowiązujące podatki) za wszelkie zamówione usługi
        oferowane na sprzedaż za pośrednictwem serwisów. Należy pamiętać, że możemy podjąć kroki w celu weryfikacji
        Twojego
        mechanizmu płatności, co może obejmować zainicjowanie niewielkiej (zwykle około 1,00 USD) autoryzacji Twojego
        mechanizmu płatności. Autoryzacja testowa zostanie niezwłocznie
        cofnięta w terminie wymaganym przez zasady sieci płatniczej. Jeśli nie otrzymamy płatności za pomocą używanego
        przez Ciebie mechanizmu płatności, zobowiązujesz się do zapłaty wszystkich kwot należnych na mocy niniejszej
        umowy na nasze żądanie i będziesz ponosić wyłączną odpowiedzialność za wszelkie spory z dostawcą płatności.
        Jeśli początkowo podany mechanizm płatności zostanie odrzucony z powodu niewystarczających środków lub z
        jakiegokolwiek innego powodu, zastrzegamy sobie prawo do podjęcia próby ponownego obciążenia mechanizmu
        płatności pełną kwotą lub w mniejszych ratach początkowo poniesionej opłaty przez okres trwania wybranej
        subskrypcji konta i przez maksymalnie 90 dodatkowych dni. Nie zostaniesz obciążony kwotą wyższą niż kwota, za
        którą zakupiłeś subskrypcję konta. Ponosisz wyłączną odpowiedzialność za wszelkie opłaty za przekroczenie salda
        i/lub kary, które mogą zostać naliczone przez dostawcę usług płatniczych. Korzystamy z usług zewnętrznego
        dostawcy usług w celu przetwarzania płatności w naszym imieniu. Przyjmujesz do wiadomości i wyrażasz zgodę na
        to, że w przypadku, gdy zewnętrzny podmiot przetwarzający płatności dozna naruszenia danych, które wpłynie na
        Twoje dane nie z naszej winy, nie będziemy w żaden sposób odpowiedzialni za takie naruszenie.

        (ii) Subskrypcja i jej odnowienie

        Aktywując subskrybcję systemu upoważniasz nas do obciążenia Cię kosztami za okres subskrypcji, który wynosi rok
        od dnia zakupu. Subskrypcja nie odnawia się automatycznie, a odnowienie subskrypcji jest możliwe w dowolnym
        momencie po jej zakończeniu, tylko i wyłącznie na identyczny zakres czasu jak pierwszy okresu subskrypcji.
        Będziemy pobierać opłaty za pomocą mechanizmu płatności według obowiązującej w danym momencie ceny.

        2. Własność serwisów i licencje

        A. Własność

        W stosunkach między nami a użytkownikiem, serwisy (w tym ich przeszłe, obecne i przyszłe wersje) są naszą
        własnością i są przez nas kontrolowane, a ich treści są chronione polskimi i międzynarodowymi prawami
        autorskimi, znakami towarowymi, szatą graficzną, patentami i innymi prawami własności intelektualnej w
        najszerszym możliwym zakresie. Treść oznacza wszystkie teksty, grafiki, interfejsy użytkownika, interfejsy
        wizualne, zdjęcia, logo, dźwięki, muzykę, ilustracje i kod komputerowy wyświetlane w serwisach lub udostępniane
        za ich pośrednictwem, a także projekt, strukturę, wybór, koordynację, formę i układ takich materiałów, w tym
        między innymi (i) materiały i inne elementy związane z nami oraz naszymi produktami i usługami, w tym między
        innymi wszelkie działania, materiały do druku, postacie, zdjęcia, klipy audio, dźwięki, obrazy, filmy i
        animacje; (ii) znaki towarowe, logo, nazwy handlowe, znaki usługowe i identyfikatory handlowe różnych podmiotów,
        w tym nasze (łącznie „Znaki towarowe”); oraz (iii) inne formy własności intelektualnej.

        B. Ograniczona licencja

        Z zastrzeżeniem stosowania się użytkownika do niniejszego regulaminu, wszelkich obowiązujących warunków
        dodatkowych, korzystania przez użytkownika z naszych serwisów i/lub uiszczania przez użytkownika wszelkich
        obowiązujących opłat za subskrypcję, udzielamy użytkownikowi ograniczonej, niewyłącznej, odwołalnej,
        niezbywalnej i nieprzenoszalnej licencji (Licencja) na dostęp, wyświetlanie, przeglądanie, używanie, odtwarzanie
        i/lub drukowanie jednej kopii (z wyłączeniem niektórych materiałów do druku udostępnianych za pośrednictwem
        naszych serwisów, które zawierają informację o możliwości ich wielokrotnego drukowania) treści na komputerze
        osobistym, telefonie komórkowym lub innym urządzeniu bezprzewodowym lub innym urządzeniu z dostępem do internetu
        (każde z nich to urządzenie internetowe) wyłącznie do osobistego, niekomercyjnego użytku. Licencja nie daje
        użytkownikowi żadnych praw własności ani żadnych innych praw własności intelektualnej do jakichkolwiek treści
        lub usług, a użytkownik nie może w inny sposób korzystać z treści lub usług bez naszej uprzedniej wyraźnej
        pisemnej zgody. Wszelkie prawa, które nie zostały wyraźnie przyznane użytkownikowi, są zastrzeżone przez nas
        i/lub naszych licencjodawców i inne strony trzecie. Z wyjątkiem przypadków wyraźnie określonych w niniejszym
        regulaminie lub za naszą uprzednią wyraźną pisemną zgodą, żadna część usług ani treści nie może być
        wykorzystywana, kopiowana, powielana, rozpowszechniana, przesyłana, publikowana, publicznie wyświetlana,
        tłumaczona, transmitowana, nadawana, sprzedawana, licencjonowana ani w żaden inny sposób wykorzystywana w
        jakimkolwiek celu. Zabrania się jakiegokolwiek nieautoryzowanego korzystania z treści lub usług w jakimkolwiek
        celu.

        Zobowiązujesz się nie modyfikować treści w żaden sposób ani w żadnej formie, ani nie używać zmodyfikowanych
        wersji treści, w tym (bez ograniczeń) w celu uzyskania nieautoryzowanego dostępu do serwisu. Zobowiązujesz się
        nie uzyskiwać dostępu do serwisu w żaden inny sposób niż za pośrednictwem interfejsu zapewnionego przez
        upadlosc-ar-expert.pl w celu uzyskania dostępu do serwisu. Niniejsza licencja może zostać odwołana przez nas w
        dowolnym
        momencie.

        Zobowiązujesz się nie wyświetlać ani nie używać naszych znaków towarowych w jakikolwiek sposób bez naszej
        uprzedniej zgody.

        C. Aktywność użytkownika w systemie

        Nasze usługi obejmują również generowanie i utrzymywanie zapisu aktywności każdego użytkownika, historii
        logowań, płatności oraz modyfikacji danych wprowadzanych przez użytkownika za pośrednictwem serwisu.

        3. Treści przesyłane przez użytkowników

        Przyjmujesz do wiadomości, że wszystkie informacje, dane, tekst, oprogramowanie, muzyka, dźwięk, zdjęcia,
        grafika, filmy, wiadomości, tagi lub inne materiały, zarówno udostępnione publicznie czy transmitowane
        prywatnie, stanowią jedyną odpowiedzialność osoby, która jest ich autorem. To znaczy, że to Ty, a nie
        usługodawca,
        jesteś w pełni odpowiedzialny za całą treść, którą przesyłasz, publikujesz, wysyłasz emailem, transmitujesz lub
        w inny sposób udostępniasz za pośrednictwem serwisu. Nie kontrolujemy treści publikowanej za pośrednictwem
        serwisu oraz nie gwarantujemy dokładności, integralności lub jakości takiej treści. Usługodawca nigdy i pod
        żadnym pozorem nie będzie w żaden sposób odpowiadać za jakąkolwiek
        treść, w tym m. in. za żadne błędy lub zaniedbania w jakiejkolwiek treści lub za jakąkolwiek stratę lub szkodę,
        wywołaną w rezultacie korzystania z jakiejkolwiek treści opublikowanej, wysłanej emailem, transmitowanej lub w
        inny sposób udostępnionej za pośrednictwem serwisu.

        Użytkownik przyjmuje do wiadomości, że usługodawca może, ale nie musi dokonywać wstępnej i końcowej kontroli
        Treści, ale usługodawca i wyznaczone przez nas osoby mają prawo (ale nie obowiązek), według własnego uznania, do
        wstępnej i końcowej kontroli, odrzucenia lub usunięcia wszelkich Treści udostępnianych za pośrednictwem Usługi.
        Nie ograniczając powyższego, usługodawca i wyznaczone przez nas osoby mają prawo usunąć wszelkie Treści
        naruszające niniejszą Umowę lub w inny sposób budzące zastrzeżenia. Możemy usunąć każdą Treść w Usłudze bez
        względu na przyczynę oraz możemy zawiesić lub zakończyć działalność użytkowników bez ponoszenia
        odpowiedzialności wobec użytkownika. Zastrzegamy sobie również prawo do uzyskiwania dostępu,
        odczytywania, przechowywania i ujawniania wszelkich informacji, które naszym zdaniem są niezbędne do (i)
        spełnienia wszelkich obowiązujących przepisów prawa, regulacji, postępowań prawnych lub żądań administracji
        rządowej, (ii) egzekwowania niniejszej Umowy, w tym badania potencjalnych naruszeń niniejszej Umowy, (iii)
        wykrywania, zapobiegania lub w inny sposób rozwiązywania kwestii związanych z oszustwami, bezpieczeństwem lub
        kwestiami technicznymi, (iv) odpowiadania na prośby użytkowników o wsparcie lub (v) ochrony praw, własności lub
        bezpieczeństwa usługodawcy lub użytkowników.

        Przyjmujesz do wiadomości, że serwis i oprogramowanie wbudowane w serwis mogą zawierać elementy zabezpieczające,
        które umożliwiają ochronę materiałów cyfrowych oraz że korzystanie z tych materiałów podlega zasadom użytkowania
        określonym przez usługodawcę i/lub podmioty dostarczające usługi do serwisu. Takie wbudowane oprogramowanie może
        zawierać pliki cookie. Więcej informacji można znaleźć w naszej polityce prywatności w sekcji plików cookie.

        Użytkownik nie może podejmować prób obejścia jakichkolwiek zasad użytkowania wbudowanych w nasz serwis. Wszelkie
        nieuprawnione powielanie, publikowanie, dalsze rozpowszechnianie lub publiczne eksponowanie materiałów
        zamieszczonych w serwisie, w całości lub w części, jest surowo zabronione.

        4. Ograniczenia dotyczące korzystania z serwisów i treści

        A. Ograniczenia konta

        Usługodawca może ustanowić ogólne procedury i ograniczenia dotyczące korzystania z serwisu, w tym między innymi
        maksymalną liczbę dni, przez które przesłane treści
        będą przechowywane przez serwis, maksymalną liczbę wiadomości e-mail, które mogą być wysyłane lub odbierane
        przez konto w serwisie, maksymalny rozmiar wiadomości e-mail, które mogą być wysyłane lub odbierane przez konto
        w serwisie, maksymalną przestrzeń dyskową, która zostanie przydzielona na naszych serwerach na Twoją rzecz.
        Usługodawca nie ponosi
        odpowiedzialności za usunięcie lub niepowodzenie w przechowywaniu jakichkolwiek wiadomości i innych form
        komunikacji lub innych treści przechowywanych lub przesyłanych przez serwis. Zastrzegamy sobie prawo do
        wylogowania kont, które są nieaktywne przez dłuższy czas.


        B. Zamknięcie konta

        Możemy, z podaniem lub bez podania przyczyny i bez uprzedniego powiadomienia, natychmiastowo zamknąć, zawiesić,
        zablokować lub usunąć Twoje konto, każdy związany z nim adres e-mail oraz dostęp do serwisu. Przestrzeganie
        niniejszej umowy lub pozostałych zasad nie stanowi obietnicy ani gwarancji przyszłego dostępu do serwisu.
        Przyczyną takiego rozwiązania umowy mogą być między innymi (a) naruszenia niniejszej umowy lub innych włączonych
        umów lub wytycznych, (b) żądania organów wymiaru sprawiedliwości lub innych organów rządowych, (c) żądanie
        użytkownika (usunięcie konta z własnej inicjatywy), (d) zaprzestanie działalności serwisu lub wprowadzenie w nim
        (lub jakiejkolwiek jego części) istotnych zmian, (e) nieoczekiwane problemy techniczne lub związane z
        bezpieczeństwem, (f) dłuższe okresy braku aktywności, (g) zaangażowanie użytkownika w działalność nielegalną,
        i/lub (h) nieuiszczenie jakichkolwiek opłat należnych od użytkownika w związku z usługami. W
        przypadku wypowiedzenia umowy z powodu działań powodujących rzeczywiste, podlegające rekompensacie szkody dla
        nas (np. udział w naruszeniu danych usługodawcy), będziemy dysponować wszelkimi prawami i środkami prawnymi
        wobec
        użytkownika, włączając w to dochodzenie swoich roszczeń na drodze sądowej lub w inny sposób.

        Zamknięcie Twojego konta w naszym serwisie może obejmować podjęcie przez nas następujących działań: (a)
        usunięcie
        dostępu do wszystkich usług świadczonych w ramach serwisów, (b) usunięcie Twojego hasła i wszystkich powiązanych
        informacji, plików i treści związanych z Twoim kontem lub będących na Twoim koncie (lub jego części), (c) zakaz
        korzystania z serwisów w przyszłości.

        Ponadto użytkownik wyraża zgodę na to, że wszelkie decyzje o zamknięciu konta będą podejmowane według naszego
        wyłącznego uznania i że nie będziemy ponosić odpowiedzialności wobec użytkownika ani żadnej strony trzeciej za
        zamknięcie konta użytkownika, powiązanego z nim adresu e-mail lub dostępu do serwisów. Użytkownikowi nie
        przysługuje prawo do odwołania się od decyzji o zamknięciu konta na naszym serwisie, z wyjątkiem sytuacji, w
        których my, według własnego uznania, zdecydujemy się umożliwić takie odwołanie. Zamknięcie konta na serwisie
        nie pozbawia użytkownika prawa do otrzymania kopii lub żądania usunięcia wszelkich danych osobowych, które
        przechowujemy lub przetwarzamy dla użytkownika.

        C. Zachowanie użytkownika

        Zobowiązujesz się nie korzystać z serwisów w celu:

        przesyłanie, publikowanie, wysyłanie pocztą elektroniczną, transmitowanie lub udostępnianie w inny sposób
        jakichkolwiek materiałów zawierających wirusy komputerowe lub jakikolwiek inny kod komputerowy, pliki lub
        programy mające na celu zakłócenie, zniszczenie lub ograniczenie funkcjonalności jakiegokolwiek oprogramowania
        komputerowego lub sprzętu komputerowego lub telekomunikacyjnego;
        uzyskiwanie dostępu do serwisów lub korzystanie z nich w sposób, który mógłby spowodować wyłączenie,
        przeciążenie, uszkodzenie, zakłócenie działania serwisów, serwerów lub sieci połączonych z serwisami lub dostępu
        innej osoby do serwisów lub korzystania z nich;
        nieprzestrzeganie jakichkolwiek wymogów, procedur, zasad lub przepisów sieci połączonych z serwisami;
        pobieranie, modyfikowanie, kopiowanie, rozpowszechnianie, przesyłanie, wyświetlanie, wykonywanie,
        reprodukowanie, powielanie, publikowanie, licencjonowanie, tworzenie dzieł pochodnych lub oferowanie na sprzedaż
        jakichkolwiek informacji zawartych w usługach lub uzyskanych za ich pośrednictwem, z wyjątkiem plików
        tymczasowych, które są automatycznie przechowywane w pamięci podręcznej przeglądarki internetowej w celu
        wyświetlenia lub w inny sposób wyraźnie dozwolony w niniejszej umowie;
        powielanie, dekompilacja, inżynieria odwrotna, dezasemblacja lub dekodowanie usług (w tym wszelkich pomysłów lub
        algorytmów leżących u ich podstaw), oraz podejmowanie prób wykonania tych czynności;
        korzystanie z oprogramowania automatyzującego (boty) lub innego nieautoryzowanego
        oprogramowania osób trzecich przeznaczonego do modyfikacji serwisów;

        uzyskiwania dostępu, manipulowania lub korzystania z niepublicznych obszarów serwisów, systemów komputerowych
        należących do usługodawcy lub technicznych systemów naszych dostawców;
        sondowania, skanowania lub testowania słabych punktów jakiegokolwiek systemu lub sieci, lub naruszania, lub
        obchodzenia jakichkolwiek środków bezpieczeństwa lub uwierzytelniania;
        używania jakichkolwiek robotów, spiderów, aplikacji do wyszukiwania i pobierania witryn lub innych ręcznych lub
        automatycznych urządzeń lub procesów do pobierania, indeksowania lub „pozyskiwania” danych lub w jakikolwiek
        sposób odtwarzania lub obchodzenia struktury nawigacyjnej lub prezentacji serwisów lub ich zawartości;
        wielokrotnego anulowania zamówień naruszającego naszą politykę zwrotów;
        umyślne lub nieumyślne naruszenie jakiegokolwiek obowiązującego prawa krajowego lub
        międzynarodowego oraz wszelkich przepisów mających moc prawną;
        uzyskiwanie dostępu do serwisu lub korzystanie z niego w sposób, który nie został wyraźnie dozwolony w
        niniejszej umowie.
        Zobowiązujesz się nie powielać, duplikować, kopiować, sprzedawać, wymieniać, odsprzedawać ani wykorzystywać w
        celach komercyjnych jakiejkolwiek części serwisów, korzystania z serwisów
        lub dostępu do serwisów. Wyrażasz zgodę na to, że Twoje konto na serwisie jest niezbywalne.


        D. Zastosowanie w skali międzynarodowej

        Usługi te są świadczone przez usługodawcę z serwerów zdalnych ulokowanych w Rzeczpospolitej Polsce. Nie
        gwarantujemy, że te
        usługi są odpowiednie lub dostępne w innych lokalizacjach. Osoby, które decydują się na korzystanie z naszych
        usług z innych lokalizacji, robią to z własnej inicjatywy i są odpowiedzialne za przestrzeganie lokalnych
        przepisów prawa, jeśli mają one zastosowanie.

        5. Łączenie z naszymi serwisami

        A. Łączenie z naszymi serwisami lub korzystanie z technik kadrowania

        Łącząc się z naszymi Usługami, użytkownik musi przestrzegać następujących wymogów: (i) łącze do Usług nie może
        stwarzać fałszywego wrażenia, że witryna i/lub organizacja użytkownika jest sponsorowana, wspierana, zależna lub
        powiązana z nami; oraz (ii) użytkownik nie może łączyć się z Usługami z witryny, która jest nielegalna, promuje
        przemoc lub nielegalne działania albo zawiera treści rasistowskie.

        W związku z tymi ograniczeniami zastrzegamy sobie prawo do zakazania tworzenia linków do Usług lub ich
        osadzania.

        6. Prawo właściwe

        NINIEJSZY REGULAMIN ORAZ WSZELKIE DODATKOWE WARUNKI BĘDĄ PODLEGAĆ PRAWU RZECZYPOSPOLITEJ POLSKI I BĘDĄ
        INTERPRETOWANE ZGODNIE
        Z NIM, BEZ WZGLĘDU NA NORMY KOLIZYJNE.

        7. Arbitraż i rozstrzyganie sporów

        NINIEJSZE WARUNKI WYMAGAJĄ BY OBIE STRONY ROZSTRZYGAŁY WSZELKIE WZAJEMNE SPORY W DRODZE ARBITRAŻU ORAZ BY OBIE
        STRONY ZRZEKŁY SIĘ PRAWA DO DOCHODZENIA ROSZCZEŃ PRZED SĄDEM, DO ROZPATRYWANIA SPORÓW PRZEZ SĘDZIEGO LUB ŁAWĘ
        PRZYSIĘGŁYCH, A TAKŻE DO DOCHODZENIA ROSZCZEŃ PRZED SĄDEM W RAMACH POZWU ZBIOROWEGO LUB POWÓDZTWA
        PRZEDSTAWICIELSKIEGO.

        UŻYTKOWNIK POWINIEN UWAŻNIE PRZECZYTAĆ NINIEJSZE POSTANOWIENIA DOTYCZĄCE ARBITRAŻU I ROZSTRZYGANIA SPORÓW, ABY
        ZROZUMIEĆ PRZYSŁUGUJĄCE MU PRAWA. UŻYTKOWNIK PRZYJMUJE DO WIADOMOŚCI, ŻE WSZELKIE ROSZCZENIA, KTÓRE MOŻE MIEĆ W
        PRZYSZŁOŚCI, BĘDĄ ROZSTRZYGANE W SPOSÓB INDYWIDUALNY W DRODZE WIĄŻĄCEGO ARBITRAŻU. UŻYTKOWNIK PRZYJMUJE DO
        WIADOMOŚCI I ZGADZA SIĘ, ŻE ZRZEKA SIĘ PRAWA DO PROCESU PRZED ŁAWĄ PRZYSIĘGŁYCH. PRAWA, KTÓRE PRZYSŁUGIWAŁYBY
        UŻYTKOWNIKOWI W PRZYPADKU WNIESIENIA SPRAWY DO SĄDU, TAKIE JAK UJAWNIENIE INFORMACJI LUB PRAWO DO WNIESIENIA
        APELACJI, MOGĄ BYĆ BARDZIEJ OGRANICZONE LUB MOGĄ NIE ISTNIEĆ.

        ABY DOWIEDZIEĆ SIĘ, JAK ODSTĄPIĆ OD OBOWIĄZKOWEGO ARBITRAŻU, NALEŻY ZAPOZNAĆ SIĘ Z PUNKTEM F PONIŻEJ.
        
        W przypadku jakichkolwiek kontrowersji, zarzutów lub roszczeń na podstawie jakiejkolwiek teorii prawnej, w tym
        między innymi roszczeń wynikających z prawa precedensowego, w tym roszczeń
        opartych na czynach niedozwolonych, oszustwach, wprowadzeniu w błąd lub umowach, wynikających z lub związanych z
        serwisami lub niniejszymi warunkami lub ich naruszeniem, rozwiązaniem, egzekwowaniem, interpretacją, utworzeniem
        lub ważnością, a także kwestią zdatności arbitrażowej (łącznie „Spór”), obie strony zgadzają się na następujący
        proces rozstrzygania w odniesieniu do sporu. Wszystkie kwestie, w tym kwestie związane z zakresem, zasadnością,
        zerwaniem i wykonalnością niniejszej umowy arbitrażowej, będą rozstrzygane przez arbitra. Strony uznają, że
        niniejsze warunki stanowią dowód transakcji.

        Przepisy arbitrażowe w polsce? https://www.biznes.gov.pl/pl/portal/001087

        W celu rozstrzygnięcia sporu w najbardziej sprawny sposób, obie strony zgadzają się najpierw omówić spór w
        sposób nieformalny przez co najmniej 30 dni. W tym celu strona, która chce zgłosić spór, musi najpierw wysłać
        drugiej stronie zawiadomienie, które musi zawierać (1) opis sporu i (2) proponowane rozwiązanie (zwane dalej
        łącznie zawiadomieniem o sporze). Jeśli użytkownik chce zgłosić spór, to musi wysłać zawiadomienie o sporze
        listem poleconym na adres podany poniżej w podpunkcie I). Jeśli będziemy chcieli następnie omówić z
        użytkownikiem jego zawiadomienie o sporze, skontaktujemy się z nim, korzystając z danych kontaktowych zawartych
        w zawiadomieniu o sporze. Jeśli będziemy chcieli zgłosić spór, wyślemy użytkownikowi zawiadomienie o sporze na
        adres e-mail, który mamy zapisany w rejestrze. Jeśli nie posiadamy ważnego adresu e-mail użytkownika, wyślemy mu
        nasze zawiadomienie o sporze w sposób zgodny z przepisami dotyczącymi doręczeń procesowych obowiązującymi w
        Rzeczpospolitej Polsce.

        Jeśli użytkownik i my nie osiągniemy uzgodnionego rozwiązania w ciągu 30 dni od otrzymania zawiadomienia o
        sporze, wówczas obie strony zgadzają się, że spór zostanie rozstrzygnięty wyłącznie w drodze wiążącego arbitrażu
        zgodnie z obowiązującym w danym czasie regulaminem arbitrażu handlowego Amerykańskiego Stowarzyszenia
        Arbitrażowego (AAA). Postępowanie arbitrażowe będzie rozpatrywane i rozstrzygane przez jednego neutralnego
        arbitra będącego prawnikiem lub sędzią w stanie spoczynku, który będzie prowadził postępowanie zgodnie z
        regulaminem arbitrażu konsumenckiego AAA. Rozstrzygając spór, arbiter weźmie pod uwagę obowiązujące przepisy
        prawa, postanowienia niniejszych zasad i wszelkich dodatkowych warunków oraz wszelkie fakty oparte na zapisach i
        nie będzie kierował się żadną inną podstawą, a następnie wyda uzasadnioną decyzję. Jeśli jedna ze stron
        prawidłowo zgłosi spór do AAA do arbitrażu, a AAA nie chce lub nie może wyznaczyć daty rozprawy w ciągu 60 dni
        od złożenia „żądania arbitrażu”, wówczas każda ze stron może zdecydować się na arbitraż administrowany przez
        firmę Judicial Arbitration and Mediation Services, Inc. (JAMS), i rozstrzygnięcie przez jednego neutralnego
        arbitra, który jest prawnikiem lub sędzią w stanie spoczynku, przy użyciu uproszczonych zasad i procedur
        arbitrażowych JAMS lub przez dowolną inną usługę administrowania arbitrażem, na którą użytkownik i my wyrazimy
        zgodę. Procedury, zasady i informacje o opłatach AAA i JAMS można uzyskać w następujący sposób:

        AAA: 800.778.7879 / JAMS: 800.352.5267

        http://www.adr.org http://www.jamsadr.com
        
        A. Charakterystyka, ograniczenia i lokalizacja alternatywnych metod rozwiązywania sporów

        W postępowaniu arbitrażowym, podobnie jak w postępowaniu sądowym, arbiter rozstrzyga przedłożony spór i może
        wydać decyzję zgodną z niniejszą sekcją 7. Jednakże W POSTĘPOWANIU ARBITRAŻOWYM NIE MA SĘDZIEGO ANI ŁAWY
        PRZYSIĘGŁYCH; POSTĘPOWANIE ARBITRAŻOWE I ARBITRAŻ PODLEGAJĄ OKREŚLONYM ZASADOM POUFNOŚCI, A SĄDOWA KONTROLA
        WYNIKÓW POSTĘPOWANIA ARBITRAŻOWEGO JEST OGRANICZONA. Wszystkie strony postępowania arbitrażowego będą miały
        prawo, na własny koszt, do bycia reprezentowanymi przez adwokata lub innego wybranego przez siebie rzecznika.
        Zarówno użytkownik, jak i my wyrażamy zgodę na to, aby wszelkie postępowania arbitrażowe były prowadzone
        wirtualnie. Użytkownik i my pokryjemy opłaty administracyjne i arbitrażowe oraz inne koszty zgodnie z
        obowiązującymi zasadami arbitrażu, ale jeśli obowiązujące zasady arbitrażu lub przepisy prawa wymagają od nas
        pokrycia większej części lub wszystkich takich opłat i kosztów w celu wyegzekwowania postanowień niniejszej
        sekcji 7, wówczas będziemy mieli prawo zdecydować się na pokrycie opłat i kosztów oraz przystąpienie do
        arbitrażu. Ujawnienie informacji będzie możliwe zgodnie z obowiązującymi zasadami arbitrażu. Decyzja arbitra
        musi składać się z pisemnego oświadczenia określającego rozstrzygnięcie każdego roszczenia dotyczącego sporu i
        musi zawierać oświadczenie o istotnych ustaleniach i wnioskach, na których opiera się decyzja i ewentualne
        orzeczenie. Wyrok w sprawie decyzji arbitrażowej i ewentualnego orzeczenia może zostać przekazany do dowolnego
        sądu właściwego dla stron.
        
        B. Sprawy dotyczące drobnych roszczeń są wyłączone

        W drodze wyjątku od wiążącego arbitrażu, obie strony zachowują prawo do dochodzenia w sądzie ds. drobnych
        roszczeń w hrabstwie Utah w stanie Utah wszelkich sporów podlegających jurysdykcji tego sądu i toczących się na
        zasadzie indywidualnej (nie zbiorowej). Nie będziemy domagać się arbitrażu w związku z jakimkolwiek
        indywidualnym sporem, który użytkownik prawidłowo zgłosi i będzie dochodził w sądzie ds. drobnych roszczeń,
        dopóki spór będzie toczył się wyłącznie w tym sądzie.
        
        C. Zabezpieczenie roszczeń

        Powyższe postanowienia niniejszej sekcji 7 nie będą miały zastosowania do jakichkolwiek działań prawnych
        podejmowanych przez którąkolwiek ze stron w celu uzyskania nakazu sądowego lub innego słusznego zadośćuczynienia
        w związku z jakimkolwiek roszczeniem dotyczącym własności intelektualnej lub roszczeniem związanym z
        nieautoryzowanym dostępem do danych za pośrednictwem serwisów (w tym między innymi roszczeń związanych z
        patentami, prawami autorskimi, znakami towarowymi i tajemnicami handlowymi oraz roszczeń związanych z dostępem
        lub pobieraniem danych za pośrednictwem serwisów przy użyciu zautomatyzowanego procesu, takiego jak scraping).

        D. Termin realizacji roszczenia

        Aby pomóc w szybkim i bezpośrednim rozwiązaniu wszelkich sporów między użytkownikiem a naszą firmą, użytkownik i
        firma zobowiązują się do przesyłania wszelkich zawiadomień o sporach lub składania skarg dotyczących drobnych
        roszczeń lub nakazów sądowych dozwolonych na mocy niniejszej sekcji 7 w ciągu jednego roku od wystąpienia
        zdarzeń powodujących spór; w przeciwnym razie spór zostaje unieważniony.

        E. Brak pozwów zbiorowych.

        Zarówno Ty, jak i firma wyrażacie zgodę na to, że każde z nas może wnosić roszczenia przeciwko drugiej stronie
        wyłącznie indywidualnie, a nie jako powód lub członek grupy w jakimkolwiek domniemanym pozwie lub postępowaniu
        zbiorowym lub przedstawicielskim. Obie strony wyrażają również zgodę na to, że wszelkie spory będą rozstrzygane
        wyłącznie indywidualnie i nie będą konsolidowane z innymi postępowaniami arbitrażowymi lub innymi
        postępowaniami, które obejmują jakiekolwiek roszczenia lub kontrowersje jakiejkolwiek innej strony. Ponadto,
        arbiter może przyznać zadośćuczynienie (w tym zadośćuczynienie pieniężne, nakazowe i deklaratoryjne) wyłącznie
        na rzecz indywidualnej strony wnoszącej o zadośćuczynienie i tylko w zakresie niezbędnym do zapewnienia
        zadośćuczynienia wymaganego przez indywidualne roszczenie tej strony.

        F. Zrzeczenie się

        Możesz zdecydować się na odrzucenie niniejszej umowy arbitrażowej, wysyłając nam pisemne powiadomienie o
        rezygnacji (Powiadomienie o rezygnacji) w ciągu 30 dni od daty pierwszej zgody na niniejsze warunki, wysyłając
        powiadomienie pocztą lub e-mailem na adres podany poniżej w podpunkcie I. W przypadku wysyłki pocztą,
        powiadomienie o rezygnacji musi zostać wysłane listem poleconym i ostemplowane nie później niż 30 dni od daty
        pierwszej zgody na niniejsze warunki. Aby powiadomienie o rezygnacji było wiążące, musi zawierać imię i
        nazwisko, adres i Twój podpis. Jeśli zrezygnujesz z umowy arbitrażowej, wszystkie pozostałe warunki niniejszego
        regulaminu będą nadal obowiązywać. Rezygnacja z niniejszej umowy arbitrażowej nie ma wpływu na żadne
        wcześniejsze, inne lub przyszłe umowy arbitrażowe, które możesz zawrzeć z firmą.

        G. Obowiązywanie umowy arbitrażowej

        Zarówno Ty, jak i firma wyrażacie zgodę na to, że umowa o arbitraż będzie obowiązywać po zakończeniu
        obowiązywania niniejszych warunków, a także po wygaśnięciu jakiegokolwiek członkostwa lub konta, które posiadasz
        w firmie.

        H. Zerwanie umowy arbitrażowej

        Jeśli arbiter zdecyduje, że obowiązujące prawo wyklucza egzekwowanie któregokolwiek z ograniczeń niniejszej
        umowy arbitrażowej w odniesieniu do konkretnego roszczenia o zadośćuczynienie, wówczas roszczenie to (i tylko to
        roszczenie) zostanie wyłączone z arbitrażu i może zostać wniesione do sądu. Ponadto, niezależnie od
        jakichkolwiek odmiennych postanowień, jeżeli jakakolwiek część niniejszej umowy arbitrażowej zostanie uznana za
        nieważną lub niemającą zastosowania, pozostała część umowy arbitrażowej będzie nadal uznawana za ważną i możliwą
        do zrealizowania.

        I. Kontakt w sprawie powiadamiania o sporach

        Upadłość Konsumencka - Kancelaria Adam Rogalski

        ul. Nadbrzeżna 2,

        43-300 Bielsko-Biała, Polska

        Tel: (+48) 534 123 123

        Adres e-mail: biuro.arkancelaria@gmail.com

        Nic w niniejszym dokumencie nie będzie interpretowane jako zgoda usługodawcy na jurysdykcję jakiegokolwiek sądu
        w odniesieniu do roszczeń niezwiązanych z korzystaniem z serwisów lub niniejszego regulaminu. Jeśli z
        jakiegokolwiek powodu spór między użytkownikiem a usługodawcą zostanie skierowany do sądu, a nie na drogę
        postępowania arbitrażowego, wówczas zastosowanie będą miały przepisy prawa kraju siedziby usługodawcy, bez
        względu na
        zastosowanie jakichkolwiek przepisów kolizyjnych lub przepisów kraju zamieszkania użytkownika.

        Nie mam pojęcia jak to przetłumaczyć na polskie prawo.

        Ponadto wszelkie takie spory będą wnoszone wyłącznie do Sądu Okręgowego Stanów Zjednoczonych w hrabstwie Salt
        Lake w stanie Utah lub Sądu Okręgowego Stanu Utah w hrabstwie Utah w stanie Utah w Stanach Zjednoczonych.
        Użytkownik wyraża zgodę na jurysdykcję i właściwość miejscową takich sądów i zrzeka się wszelkich zastrzeżeń, że
        jest to niedogodne miejsce rozstrzygania sporów.

        8. Wyłączenie odpowiedzialności z tytułu oświadczeń i gwarancji
        
        DOSTĘP DO SERWISÓW I KORZYSTANIE Z NICH ODBYWA SIĘ NA WYŁĄCZNE RYZYKO UŻYTKOWNIKA.

        USŁUGI SĄ ŚWIADCZONE NA ZASADZIE „W STANIE, W JAKIM SIĘ ZNAJDUJĄ”, „W MIARĘ DOSTĘPNOŚCI” I „ZE WSZYSTKIMI
        WADAMI”. W związku z tym, w najszerszym zakresie dozwolonym przez prawo, my i nasza spółka macierzysta, podmioty
        stowarzyszone, podmioty zależne oraz każdy z ich poszczególnych pracowników, członków, dyrektorów, kierowników,
        udziałowców, agentów, sprzedawców, licencjodawców, licencjobiorców, wykonawców, klientów, następców i
        cesjonariuszy (łącznie usługodawca) niniejszym, w maksymalnym zakresie dozwolonym przez obowiązujące prawo,
        zrzekamy się i nie udzielamy żadnych oświadczeń, gwarancji, rekomendacji ani obietnic, wyraźnych lub
        dorozumianych, w odniesieniu do następujących kwestii:

        Serwisami (w tym treściami i treściami tworzonymi przez użytkowników, i treściami generowanymi automatycznie na
        podstawie treści użytkowników);
        funkcjami, właściwościami lub innymi elementami serwisów lub udostępnianymi za ich pośrednictwem;
        wszelkich produktów, usług lub instrukcji oferowanych lub wspominanych w serwisach lub dostępnych za ich
        pośrednictwem;
        czy usługi (i ich treść) lub serwery, które udostępniają usługi, są wolne od jakichkolwiek szkodliwych elementów
        (w tym wirusów, koni trojańskich i innych technologii, które mogą mieć negatywny wpływ na urządzenie internetowe
        użytkownika);
        konkretnej dostępności serwisów oraz tego, czy wszelkie usterki w serwisach zostaną naprawione lub czy zostaną
        naprawione w określonym czasie; oraz
        czy korzystanie z usług przez użytkownika jest zgodne z prawem w danej jurysdykcji.
        Z WYJĄTKIEM PRZYPADKÓW WYRAŹNIE OKREŚLONYCH W DODATKOWYCH WARUNKACH, USŁUGODAWCA NINIEJSZYM ZRZEKA SIĘ WSZELKICH
        GWARANCJI, WYRAŹNYCH LUB DOROZUMIANYCH, W TYM GWARANCJI PRZYDATNOŚCI HANDLOWEJ, PRZYDATNOŚCI DO OKREŚLONEGO
        CELU, NIENARUSZANIA LUB SPRZENIEWIERZENIA PRAW WŁASNOŚCI INTELEKTUALNEJ STRON TRZECICH, TYTUŁU, ZWYCZAJU,
        HANDLU, CICHEJ PRZYJEMNOŚCI, INTEGRACJI SYSTEMU ORAZ WOLNOŚCI OD BŁĘDÓW, WIRUSÓW KOMPUTEROWYCH LUB INNYCH
        SZKODLIWYCH ELEMENTÓW.

        Niektóre jurysdykcje ograniczają lub nie zezwalają na wyłączenie dorozumianych lub innych gwarancji, więc
        powyższe wyłączenia mogą nie mieć zastosowania w zakresie, w jakim mają zastosowanie przepisy takich
        jurysdykcji.

        9. Ograniczenie odpowiedzialności

        A. OGRANICZENIE ODPOWIEDZIALNOŚCI

        W ŻADNYM WYPADKU USŁUGODAWCA NIE BĘDZIE PONOSIĆ ODPOWIEDZIALNOŚCI ZA ŻADNE STRATY LUB SZKODY JAKIEGOKOLWIEK
        RODZAJU, w tym obrażenia ciała lub śmierć, ani za żadne bezpośrednie, pośrednie, ekonomiczne, przykładowe,
        szczególne, karne, przypadkowe lub wtórne straty lub odszkodowania, w tym straty lub odszkodowania w postaci
        utraconych zysków, utraty wartości firmy lub utraty danych, które są bezpośrednio lub pośrednio związane z:

        Serwisami (w tym treściami i treściami tworzonymi przez użytkowników, i treściami generowanymi automatycznie na
        podstawie treści użytkowników);
        korzystaniem lub niemożnością korzystania przez użytkownika z serwisów lub działaniem serwisów;
        niepowodzeniem użytkownika w osiągnięciu korzyści z ogłoszenia upadłości w związku z korzystaniem przez
        niego z serwisów;
        wszelkimi działaniami podjętymi w związku z dochodzeniem prowadzonym przez nas lub organy ścigania w sprawie
        dostępu użytkownika do serwisów lub korzystania z nich;
        wszelkimi działaniami podjętymi w związku z właścicielami praw autorskich lub innych praw własności
        intelektualnej lub innymi właścicielami praw;
        wszelkimi błędami lub niedopatrzeniami w technicznym działaniu serwisów; lub
        wszelkimi uszkodzeniami komputera, sprzętu, oprogramowania, modemu lub innego sprzętu lub technologii
        użytkownika, w tym uszkodzeniami spowodowanymi naruszeniem bezpieczeństwa lub wirusami, błędami, manipulacjami,
        oszustwami, usterkami, pominięciami, przerwami, wadami, opóźnieniami w działaniu lub transmisji, awariami linii
        komputerowej lub sieci lub innymi awariami technicznymi i innymi rodzajami usterek, w tym stratami lub
        odszkodowaniami w postaci utraconych zysków, utraty wartości firmy, utraty danych, przestojów w pracy,
        niedokładności wyników lub awarii bądź nieprawidłowego działania sprzętu.
        Powyższe ograniczenia odpowiedzialności będą miały zastosowanie, nawet jeśli którekolwiek ze zdarzeń lub
        okoliczności można było przewidzieć i nawet jeśli zostaliśmy poinformowani lub powinniśmy byli wiedzieć o
        możliwości wystąpienia takich strat lub szkód, niezależnie od tego, czy wniesiesz powództwo z tytułu umowy,
        zaniedbania, ścisłej odpowiedzialności lub czynu niedozwolonego (w tym spowodowanego, w całości lub w części,
        zaniedbaniem, siłą wyższą, awarią telekomunikacyjną lub zniszczeniem serwisów).

        Niektóre jurysdykcje nie zezwalają na wyłączenie lub ograniczenie odszkodowań przypadkowych lub wynikowych
        opisanych powyżej, więc powyższe ograniczenie lub wyłączenie może nie mieć zastosowania do użytkownika.

        Z WYJĄTKIEM PRZYPADKÓW OKREŚLONYCH W DODATKOWYCH WARUNKACH, W NAJSZERSZYM ZAKRESIE DOZWOLONYM PRZEZ OBOWIĄZUJĄCE
        PRAWO, W ŻADNYM WYPADKU CAŁKOWITA KWOTA ODSZKODOWANIA PONIESIONA PRZEZ USŁUGODAWCĘ W ZWIĄZKU Z DOSTĘPEM I
        KORZYSTANIEM Z USŁUG ORAZ PRAWAMI UŻYTKOWNIKA WYNIKAJĄCYMI Z NINIEJSZYCH WARUNKÓW NIE PRZEKROCZY KWOTY
        ZAPŁACONEJ NAM PRZEZ UŻYTKOWNIKA W CIĄGU OSTATNICH 12 MIESIĘCY ZA WSZELKIE MOŻLIWE SZKODY, STRATY I PRZYCZYNY
        DZIAŁAŃ.

        B. WYŁĄCZENIA I OGRANICZENIA

        NIEKTÓRE JURYSDYKCJE NIE ZEZWALAJĄ NA WYŁĄCZENIE NIEKTÓRYCH GWARANCJI LUB OGRANICZENIE BĄDŹ WYŁĄCZENIE
        ODPOWIEDZIALNOŚCI ZA SZKODY PRZYPADKOWE LUB WYNIKOWE. W ZWIĄZKU Z TYM NIEKTÓRE Z POWYŻSZYCH OGRANICZEŃ MOGĄ NIE
        MIEĆ ZASTOSOWANIA W ODNIESIENIU DO CIEBIE.

        C. Łącza do sieci WWW

        Serwisy mogą udostępniać łącza do innych witryn lub zasobów sieci WWW, w tym
        osadzonych treści od zewnętrznych dostawców. Ze względu
        na to, że usługodawca nie ma kontroli nad takimi witrynami i zasobami, przyjmujesz do wiadomości i wyrażasz
        zgodę
        na to, że nie ponosimy odpowiedzialności za dostępność takich zewnętrznych witryn lub zasobów, a także nie
        popieramy i nie ponosimy odpowiedzialności za jakiekolwiek treści, reklamy, produkty lub inne materiały dostępne
        na takich witrynach lub w takich zasobach. Nie gwarantujemy przydatności jakichkolwiek treści stron trzecich do
        określonego użytku lub celu. Ponadto przyjmujesz do
        wiadomości i wyrażasz zgodę na to, że nie ponosimy odpowiedzialności, bezpośrednio lub pośrednio, za
        jakiekolwiek szkody lub straty spowodowane lub rzekomo spowodowane w związku z korzystaniem lub poleganiem na
        takich treściach, towarach lub usługach dostępnych za pośrednictwem takiej witryny lub takiego zasobu.
        
        10. Odszkodowanie

        W maksymalnym zakresie dozwolonym przez prawo, wyrażasz zgodę na zabezpieczenie i obronę usługodawcy przed
        wszelkimi stratami, wydatkami, odszkodowaniami i kosztami, w tym uzasadnionymi honorariami prawników,
        wynikającymi z: (a) naruszenia lub domniemanego naruszenia niniejszych warunków przez Ciebie; (b) korzystania
        przez Ciebie z serwisów lub działań związanych z serwisami; (c) treści generowanych przez Ciebie; (d) naruszenia
        przez Ciebie jakichkolwiek przepisów prawa, zasad lub postanowień. Zastrzegamy sobie prawo do podjęcia, na
        własny koszt, wyłącznej obrony i kontroli wszelkich
        spraw podlegających odszkodowaniu przez Ciebie, w którym to przypadku będziesz w pełni współpracować z nami w
        dochodzeniu wszelkich dostępnych środków obrony. W żadnym wypadku nie będziesz regulować żadnych roszczeń bez
        naszej uprzedniej pisemnej zgody. Jeśli jakakolwiek osoba upoważniona przez Ciebie do korzystania z serwisów lub
        uzyskiwania do nich dostępu odmówi uznania którejkolwiek z niniejszych zasad, zobowiązujesz się bronić i
        zabezpieczyć nas przed wszelkimi szkodami, jakie poniesiemy w związku z odmową takiej osoby.
        
        11. Polityka dotycząca naruszeń i procedura zgłaszania

        Jeśli uważasz, że jakakolwiek treść została skopiowana lub wykorzystana w sposób stanowiący naruszenie praw
        autorskich, prosimy o przekazanie nam następujących informacji:

        fizyczny lub elektroniczny podpis właściciela praw autorskich lub osoby upoważnionej do działania w jego
        imieniu;
        wskazanie dzieła chronionego prawem autorskim, które zostało naruszone;
        wskazanie materiału, który rzekomo narusza prawo lub jest obiektem działań naruszających prawo, oraz informacje
        wystarczające do zlokalizowania tego materiału (takie jak adres URL);
        dane kontaktowe użytkownika, w tym adres, numer telefonu i adres e-mail;
        pisemne oświadczenie użytkownika, że jest on w dobrej wierze przekonany, że wykorzystanie materiału w sposób,
        którego dotyczy skarga, nie jest dozwolone przez właściciela praw autorskich, jego przedstawiciela lub prawo;
        oraz
        oświadczenie, że informacje zawarte w powiadomieniu są poprawne oraz, pod karą krzywoprzysięstwa, że użytkownik
        jest upoważniony do działania w imieniu właściciela praw autorskich.
        Jeśli użytkownik uważa, że usunięta treść nie narusza prawa lub że użytkownik posiada niezbędne prawa do
        opublikowania takiej treści, prosimy o przesłanie nam odpowiedzi na takie powiadomienie zawierającej następujące
        informacje:

        fizyczny lub elektroniczny podpis właściciela praw autorskich lub osoby upoważnionej do działania w jego
        imieniu;
        fizyczny lub elektroniczny podpis użytkownika (z pełnym imieniem i nazwiskiem);
        wskazanie treści, która została usunięta lub do której dostęp został zablokowany, oraz miejsca, w którym treść
        ta pojawiła się przed jej usunięciem lub zablokowaniem;
        oświadczenie, że użytkownik jest w dobrej wierze przekonany, pod karą krzywoprzysięstwa, że treść została
        usunięta lub zablokowana w wyniku pomyłki lub błędnej identyfikacji treści; oraz
        imię i nazwisko użytkownika, jego adres, numer telefonu i adres e-mail, a także oświadczenie, że użytkownik
        akceptuje doręczenie pisma procesowego od osoby, która dostarczyła pierwotne powiadomienie o domniemanym
        naruszeniu.
        Jeśli otrzymamy odpowiedź na takie powiadomienie, możemy wysłać kopię odpowiedzi do osoby, która twierdzi, że
        doszło do naruszenia praw autorskich i poinformować ją, że możemy ponownie wykorzystać usuniętą treść. Jeżeli
        osoba zarzucająca naruszenie praw autorskich nie wniesie pozwu o wydanie nakazu sądowego przeciwko dostawcy
        treści, członkowi lub użytkownikowi, usunięta treść może zostać ponownie wykorzystana w ciągu dziesięciu do
        czternastu dni roboczych lub więcej po otrzymaniu odpowiedzi, według wyłącznego uznania usługodawcy.

        Należy pamiętać, że złożenie odpowiedzi na powiadomienie może prowadzić do postępowania prawnego między
        użytkownikiem a stroną wnoszącą skargę w celu ustalenia prawa własności. Należy również pamiętać, że złożenie
        fałszywego lub złożonego w złej wierze zarzutu za pomocą tej procedury może mieć negatywne konsekwencje prawne w
        kraju użytkownika.

        Zastrzegamy sobie prawo do usuwania treści rzekomo naruszających prawo bez uprzedniego powiadomienia i według
        własnego uznania. W uzasadnionych okolicznościach możemy również zamknąć konto użytkownika, jeśli zostanie on
        uznany za osobę naruszającą prawo po raz kolejny. Naszym wyznaczonym agentem ds. praw autorskich, którego można
        powiadomić o domniemanym naruszeniu praw autorskich pojawiającym się w serwisie, jest:

        Upadłość Konsumencka - Kancelaria Adam Rogalski

        ul. Nadbrzeżna 2,

        43-300 Bielsko-Biała, Polska

        Tel: (+48) 534 123 123

        Adres e-mail: biuro.arkancelaria@gmail.com
        
        12. Funkcje bezprzewodowe

        Możemy oferować pewne funkcje i usługi, które są dostępne dla Ciebie za pośrednictwem Twojego urządzenia z
        bezprzewodowym dostępem do internetu (łącznie: Funkcje bezprzewodowe). Operator może pobierać standardowe opłaty
        za wysyłanie wiadomości, transmisję danych i inne usługi, które mogą pojawić się na rachunku za usługi
        bezprzewodowe lub zostać potrącone z przedpłaconego salda. Twój operator może zabronić korzystania z niektórych
        funkcji bezprzewodowych lub je ograniczyć, a niektóre funkcje bezprzewodowe mogą być niekompatybilne z Twoim
        operatorem lub bezprzewodowym urządzeniem. Wyrażasz zgodę na to, że w odniesieniu do funkcji bezprzewodowych,
        których używasz, możemy wysyłać na Twoje bezprzewodowe urządzenie komunikaty dotyczące nas lub innych stron.
        Jeśli zarejestrowałeś się za pośrednictwem serwisów w celu korzystania z funkcji bezprzewodowych, zobowiązujesz
        się powiadomić nas o wszelkich zmianach swojego numeru bezprzewodowego (w tym numeru telefonu) i zaktualizować
        swoje konto w celu odzwierciedlenia tych zmian.

        13. Przesyłanie informacji zwrotnych

        Chętnie przyjmujemy informacje zwrotne, komentarze i sugestie dotyczące ulepszeń naszych serwisów („Informacje
        zwrotne”). Przyjmujesz do wiadomości i wyraźnie zgadzasz się na to, że jakikolwiek wkład w postaci informacji
        zwrotnych nie daje i nie będzie dawał Ci żadnych praw, tytułów ani udziałów w serwisach ani w takich
        informacjach zwrotnych. Wszystkie informacje zwrotne stają się wyłączną własnością usługodawcy, a my możemy
        wykorzystywać i ujawniać informacje zwrotne w dowolny sposób i w dowolnym celu bez dodatkowego powiadomienia lub
        rekompensaty dla Ciebie i bez zachowania przez Ciebie jakichkolwiek praw własności lub innych praw lub roszczeń.
        Niniejszym przenosisz na usługodawcę wszelkie prawa, tytuły i udziały (w tym między innymi wszelkie patenty, prawa
        autorskie, tajemnice handlowe, znaki towarowe, show-how, know-how, prawa moralne i wszelkie inne prawa własności
        intelektualnej), które możesz posiadać w odniesieniu do wszelkich informacji zwrotnych.

        14. Postanowienia ogólne

        A. Zamknięcie i zawieszenie

        Zastrzegamy sobie prawo do zaprzestania świadczenia usług, zawieszenia lub zakończenia Twojego dostępu do nich,
        w tym do wszelkich kont lub treści generowanych przez Ciebie, w dowolnym momencie, bez uprzedzenia, z dowolnego
        powodu i bez żadnych zobowiązań wobec Ciebie lub jakiejkolwiek strony trzeciej. Jeśli jakiekolwiek informacje
        podane przez Ciebie są fałszywe, niedokładne lub w inny sposób naruszają niniejszy regulamin lub jakiekolwiek
        dodatkowe warunki, lub jeśli mamy uzasadnione podstawy, aby podejrzewać, że tak jest, możemy zawiesić lub
        zamknąć Twoje konto lub odmówić Ci dostępu do całości lub części naszych usług. Zawieszenie lub zamknięcie nie
        będzie miało wpływu na Twoje zobowiązania wobec nas, w tym wszelkie zobowiązania płatnicze wobec nas, a Ty nie
        będziesz uprawniony do zwrotu jakichkolwiek płatności. Po zawieszeniu lub zakończeniu Twojego dostępu do usług
        lub po otrzymaniu od nas powiadomienia, Twoja licencja na korzystanie z usług wygaśnie ze skutkiem
        natychmiastowym.

        B. Komunikacja

        Gdy komunikujesz się z nami drogą elektroniczną, na przykład za pośrednictwem narzędzia komunikacyjnego
        dostępnego w naszych serwisach, wyrażasz zgodę na otrzymywanie od nas wiadomości drogą elektroniczną. Należy
        pamiętać, że dołożymy wszelkich starań, aby odpowiedzieć na Twoje zapytanie, ale może to zająć nam trochę czasu.
        Wyrażasz zgodę na to, że wszystkie umowy, powiadomienia, ujawnienia i inne komunikaty, które przekazujemy Ci
        drogą elektroniczną, spełniają wszelkie wymogi prawne, zgodnie z którymi takie komunikaty muszą mieć formę
        pisemną.

        C. Obsługa serwisów: kwestie międzynarodowe

        Kontrolujemy i prowadzimy serwisy z terytorium Rzeczypospolitej Polskiej. Jeśli korzystasz z serwisów z
        innych lokalizacji, jesteś odpowiedzialny za zgodność z obowiązującymi lokalnymi przepisami dotyczącymi Twojego
        zachowania online i akceptowalnych treści, w zakresie, w jakim obowiązują lokalne przepisy (na przykład wszelkie
        lokalne przepisy dotyczące edukacji lub prywatności danych). Zarówno Ty, jak i my zrzekamy się stosowania
        konwencji o umowach międzynarodowej sprzedaży towarów.

        D. Rozdzielność postanowień oraz interpretacja

        Jeśli którekolwiek z postanowień niniejszych zasad lub jakichkolwiek dodatkowych warunków zostanie z
        jakiegokolwiek powodu uznane za niewykonalne przez sąd lub arbitra, wyrażasz zgodę na to, że zostaną podjęte
        wszelkie próby nadania skuteczności intencjom stron odzwierciedlonym w tym postanowieniu, a pozostałe
        postanowienia zawarte w niniejszej umowie pozostaną w pełnej mocy. Wyrażasz zgodę na to, że niniejsze zasady i
        dodatkowe warunki nie będą interpretowane przeciwko nam, ponieważ to my je opracowaliśmy.

        E. Przeniesienie praw

        Możemy przenieść nasze prawa i obowiązki wynikające z niniejszego regulaminu lub jakichkolwiek dodatkowych
        warunków, w całości lub w części, na dowolną stronę w dowolnym czasie i bez uprzedzenia. Niniejszy regulamin i
        wszelkie dodatkowe warunki nie mogą być przez Ciebie cedowane, ani nie możesz delegować swoich obowiązków, które
        z nich wynikają.

        F. Brak możliwości odstąpienia

        Jakiekolwiek zrzeczenie się przez nas jakichkolwiek z niniejszych zasad lub jakichkolwiek dodatkowych warunków
        nie będzie miało żadnej mocy ani skutku, dopóki nie zostanie sporządzone na piśmie i podpisane przez należycie
        upoważnionego przedstawiciela usługodawcy.

        G. Aktualizacje regulaminu

        Zastrzegamy sobie prawo do zmiany niniejszego regulaminu lub jakichkolwiek dodatkowych warunków od czasu do
        czasu według własnego uznania (Zaktualizowany regulamin). Wyrażasz zgodę na to, że każdy zaktualizowany
        regulamin wejdzie w życie natychmiast po opublikowaniu go przez nas w serwisach oraz, jeśli posiadasz konto,
        poprzez wyświetlenie ostrzeżenia obok linku do regulaminu, wyświetlenie ostrzeżenia po zalogowaniu się do
        serwisów lub poprzez bezpośrednie przekazanie go Tobie (np. za pośrednictwem adresu e-mail powiązanego z Twoim
        kontem lub naszej wewnętrznej usługi przesyłania wiadomości), pod warunkiem, że: (i) jakakolwiek modyfikacja
        Sekcji 7 (Arbitraż i rozstrzyganie sporów) nie będzie miała zastosowania do sporów wszczętych przed odpowiednią
        modyfikacją; oraz (ii) jakakolwiek modyfikacja postanowień związanych z opłatami i rozliczeniami nie będzie
        miała zastosowania do opłat poniesionych przed odpowiednią modyfikacją. Jeśli nie anulujesz subskrypcji usług w
        ciągu siedmiu dni od otrzymania powiadomienia o zaktualizowanym regulaminie, jak opisano powyżej, lub jeśli
        nadal będziesz korzystać z usług po otrzymaniu powiadomienia o zaktualizowanym regulaminie, zgadzasz się
        przestrzegać i być związanym zaktualizowanym regulaminem.

        H. Skontaktuj się z nami

        Nasza siedziba znajduje się w Rzeczpospolitej Polsce. Możesz
        skontaktować się z nami poniżej, jeśli masz jakiekolwiek pytania, skargi lub inne uwagi związane z niniejszymi
        warunkami:

        Adres do korespondencji:

        Upadłość Konsumencka - Kancelaria Adam Rogalski

        ul. Nadbrzeżna 2,
        
        43-300 Bielsko-Biała, Polska

        W każdej sprawie prosimy o kontakt na adres mailowy: biuro.arkancelaria@gmail.com. 
    </div>
</template>

<script setup>
</script>