<template>
    <main>
        <form @submit.prevent="handleSubmit">
            <div v-for="(item, index) in data">
                <FormField v-model="item.val" v-bind="item" :ref="references[item.uuid]" />
            </div>
            <div v-if="props.ankieta === undefined" class="mt-5">
                <Checkbox v-model="checked" :invalid="invalid && !checked" binary inputId="policy_consultation" />
                <label for="policy_consultation" class="cursor-pointer"> Akceptuję</label>
                <a href="https://upadlosc-ar-expert.pl/data-policy-and-user-agreement" class="text-primary-500"> politykę prywatności</a>.
                <div v-if="invalid && !checked" class="pl-2 pt-1 text-sm text-red-600">Zgoda na politykę prywatności
                    jest wymagana.</div>
            </div>

            <div class="w-full flex justify-center items-center mt-7">
                <Button @click="handleSubmit()">Umów się</Button>
            </div>
        </form>
    </main>
</template>

<script setup>
import Button from 'primevue/button';
import { ref, onMounted, inject } from 'vue';
import { format } from 'date-fns';
import FormField from '@/components/forms/FormField.vue';
import Checkbox from 'primevue/checkbox';

const emit = defineEmits(['close-window']);
const showToast = inject('showToast');
const references = Array.from({ length: 4 }, (_, i) => ({}[i + ""] = ref()));

const checked = ref(false);
const invalid = ref(false);

const props = defineProps({
    name: {
        type: String,
        default: null
    },
    ankieta: {
        type: Object,
        default: undefined
    }
});

const data = ref([
    {
        uuid: '0',
        component: "InputText",
        label: "Twoje imię",
        errorLabels: ['Imię jest wymagane'],
        conditions: [{
            type: "required",
        },],
        placeholder: "Anna",
        val: props.name
    },
    {
        uuid: '1',
        component: "DatePicker",
        label: "Dzień rozmowy",
        errorLabels: ['Podanie dnia jest wymagane'],
        conditions: [{
            type: "required",
        },],
        placeholder: 'dd/mm/yyyy',//format(new Date(), 'dd/MM/yyyy'),
        innerInputProps: {
            showIcon: true,
            iconDisplay: "input",
            minDate: new Date()
        },
        val: null
    },
    {
        uuid: '2',
        component: "DatePicker",
        label: "Godzina rozmowy",
        errorLabels: ['Podanie godziny jest wymagane'],
        conditions: [{
            type: "required",
        },],
        placeholder: "gg:mm",//format(new Date(), 'HH:mm'),
        innerInputProps: {
            timeOnly: true,
            hourFormat: "24",
            stepMinute: 30
        },
        val: null
    },
    {
        uuid: '3',
        component: "InputText",
        label: "Numer telefonu",
        errorLabels: ['Numer telefonu jest wymagany', 'Numer telefonu jest nieprawidłowy'],
        conditions: [{
            type: "required",
        },
        {
            type: "regex",
            value: "^(\\(? *(\\+ *[1-9][0-9]{0,3} *)?\\)? *([0-9] *){9})?$"
        },],
        placeholder: "xxx xxx xxx",
        val: null
    }
])

const handleSubmit = async () => {
    let status = true;

    for (const reference of references.values()) {
        if (!reference.value[0].validate()) {
            status = false;
        }
    }

    if (!checked.value) {
        status = false;
        invalid.value = true;
    }

    if (status) {
        let result = await fetch('/api/consultation', {
            body: JSON.stringify({
                name: data.value[0].val,
                date: format(new Date(data.value[1].val), 'dd-MM-yyyy'),
                time: format(new Date(data.value[2].val), 'HH:mm'),
                phone: data.value[3].val,
                ankieta: props.ankieta
            }),
        }).then((r) => r.json());

        showToast('success', 'Sukces', "Umówiliśmy się na konsultację.");
        emit('close-window');
    } else {
        showToast('error', 'Błąd', "Wypełnij wszystkie pola.");
    }

}
</script>
