<template>
    <section class="bg-gray-50 dark:bg-gray-900 min-h-screen flex items-center justify-center h-full w-full">
        <div
            class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
            <RouterLink class="flex flex-col items-center justify-center px-6 py-2 mx-auto lg:py-4" to="/">
                <div class="flex items-center mb-1 text-3xl font-bold text-gray-900 dark:text-white">
                    <div class="bg-white w-[4.5rem] h-[4.5rem] mr-5 rounded-lg shadow-lg dark:border dark:border-gray-700 flex justify-center items-center">
                        <img class="w-12 h-12" src="/logo.png" alt="logo" />
                    </div>
                    
                    <div class="flex flex-col">
                        <span class="block" :class="{ 'lg:hidden': isLandingPageShown() }">Upadłość Konsumencka </span>
                        <span class="block" :class="{ 'lg:hidden': isLandingPageShown() }">Kancelaria AR</span>
                    </div>
                </div>
            </RouterLink>
            <div class="w-full bg-white rounded-lg shadow-lg dark:border dark:border-gray-700 dark:bg-gray-800">
                <div class="p-8 space-y-6 sm:p-10 ">
                    <h1 class="text-lg  leading-tight tracking-tight text-gray-900  dark:text-white text-justify">
                        {{ langObject.reset_password_header }}
                    </h1>
                    <form class="space-y-6" @submit.prevent="registerSubmit">
                        <div>
                            <FormField v-for="(pole, poleIndex) in data" :key="poleIndex" :ref="references[pole.uuid]" v-bind="pole"
                                v-model=formValues[poleIndex] @update:modelValue="custom(pole.uuid)" />
                        </div>
                        <button type="submit"
                            class="w-full text-white font-bold bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 rounded-lg text-lg px-5 py-3 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 pt-2">
                            {{ langObject.reset_password_button }}
                        </button>
                        <div class="w-full mt-4" v-if="isLoading">
                            <ProgressBar mode="indeterminate" style="height: 6px"></ProgressBar>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
import { ref, inject } from 'vue';
import { useRouter } from 'vue-router';
import { langObject } from '@/languages/pl.js';
import ProgressBar from 'primevue/progressbar';
import FormField from '@/components/forms/FormField.vue';

const showToast = inject('showToast');
const isLandingPageShown = inject('isLandingPageShown');

const isLoading = ref(false);

const router = useRouter();
const childRoutes = ref([]);
const authRoute = router.getRoutes().find(route => route.path === '/authview');

if (authRoute && authRoute.children) {
    childRoutes.value = authRoute.children;
}
const repeatPasswordValue = ref([]);
const references = Array.from({ length: 2 }, (_, i) => ({}[i + ""] = ref()));
const formValues = ref(Array(2).fill(null));

const data = ref([
    {
        uuid: '0',
        component: "Password",
        label: langObject.password,
        conditions: [{
            type: "required",
        },],
        innerInputProps: {
            feedback: true,
        },
        placeholder: "••••••••",
    },
    {
        uuid: '1',
        component: "Password",
        label: langObject.repeat_password,
        options: repeatPasswordValue,
        errorLabels: ['Hasło jest wymagane', 'Hasła się różnią'],
        conditions: [{
            type: "required",
        }, {
            type: "value",
            option: 0,
        }],
        innerInputProps: {
            feedback: false,
        },
        placeholder: "••••••••",
    }
])

function custom(uuid) {
    if (uuid == "1") repeatPasswordValue.value = [formValues.value[0]];
}

const registerSubmit = async () => {
    const token = router.currentRoute.value.query.token;
    let status = true;

    for (const reference of references.values()) {
        if (!reference.value[0].validate()) {
            status = false;
        }
    }
    if (status) {
        let loadingTimeout;
        try {
            loadingTimeout = setTimeout(() => {
                isLoading.value = true;
            }, 400);

            const response = await fetch('/api/reset-password', {
                body: JSON.stringify({
                    token: token,
                    password: formValues.value[0],
                    password2: formValues.value[1],
                }),
            });

            if (response.ok) {
                const data = await response.json();
                
                if (data.success == 1) {
                    isLoading.value = false;
                    showToast('success', 'Sukces', langObject.reset_password_success);
                    setTimeout(() => {
                        router.push({ path: childRoutes.value.find(route => route.name === 'Login').path })
                    }, 1500);
                }
                else if (data.success == 2) {
                    showToast('error', 'Błąd', langObject.error_unknown);
                }
                else {
                    throw "error"
                }
            }
        } catch (error) {
            console.log('Error occurred:', error);
            showToast('error', 'Błąd', langObject.error_unknown);
        } finally {
            clearTimeout(loadingTimeout);
            isLoading.value = false;
        }
    }
}
</script>