<template>
    <section class="bg-gray-50 dark:bg-gray-900 flex flex-col items-center justify-center h-full w-full">
        <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto flex-grow lg:py-0 h-full">
            <RouterLink class="flex flex-col items-center justify-center px-6 py-2 mx-auto lg:py-4" to="/">
                <div class="flex items-center mb-1 text-3xl font-bold text-gray-900 dark:text-white">
                    <div class="bg-white w-[4.5rem] h-[4.5rem] mr-5 rounded-lg shadow-lg dark:border dark:border-gray-700 flex justify-center items-center">
                        <img class="w-12 h-12" src="/logo.png" alt="logo" />
                    </div>
                    
                    <div class="flex flex-col">
                        <span class="block" :class="{ 'lg:hidden': isLandingPageShown() }">Upadłość Konsumencka </span>
                        <span class="block" :class="{ 'lg:hidden': isLandingPageShown() }">Kancelaria AR</span>
                    </div>
                </div>
            </RouterLink>
            <div
                class="w-full bg-white rounded-lg shadow-lg dark:border dark:border-gray-700 sm:max-w-xl dark:bg-gray-800">
                <div class="p-8 space-y-6 sm:p-10 ">
                    <h1
                        class="text-2xl font-extrabold leading-tight tracking-tight text-gray-900  dark:text-white text-center">
                        {{ langObject.login_header }}
                    </h1>
                    <p class="flex items-center justify-center text-center text-sm text-gray-600 dark:text-gray-300">
                        {{ langObject.no_account }}
                        <span class="mx-1"> </span>
                        <RouterLink class="flex items-center"
                            :to="childRoutes.find(route => route.name === 'Register').path">
                            <span class="font-medium text-primary-600 hover:underline dark:text-primary-500">{{
                                langObject.register_now }}</span>
                        </RouterLink>
                    </p>
                    <form class="space-y-4" @submit.prevent="loginSubmit">
                        <div>
                            <FormField ref="username2" v-bind="objUsername" v-model="inputUsername"
                                placeholder="example@invictus.pl" />
                        </div>
                        <div>
                            <FormField ref="password2" v-bind="objPassword" v-model="InputPassword"
                                placeholder="••••••••" />
                        </div>
                        <RouterLink class="flex justify-between items-center"
                            :to="childRoutes.find(route => route.name === 'RequestResetPassword').path">
                            <span class="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500">{{
                                langObject.forgot_password }}</span>
                        </RouterLink>
                        <button type="submit" :disabled="isLoading"
                            class="w-full text-white font-bold bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 rounded-lg text-sm px-5 py-3 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
                            <span>{{ langObject.sign_in }} </span>
                        </button>
                        <div class="card w-full mt-4" v-if="isLoading">
                            <ProgressBar mode="indeterminate" style="height: 6px"></ProgressBar>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>


<script setup>
import { ref, inject } from 'vue';
import { useRouter } from 'vue-router';
import { langObject } from '@/languages/pl.js';
import { userdataStore } from '@/stores/userData'
import ProgressBar from 'primevue/progressbar';
import FormField from '@/components/forms/FormField.vue';

const showToast = inject('showToast');
const isLandingPageShown = inject('isLandingPageShown');

const isLoading = ref(false);

const username2 = ref()
const password2 = ref();
const inputUsername = ref();

const router = useRouter();
const childRoutes = ref([]);
const authRoute = router.getRoutes().find(route => route.path === '/authview');
if (authRoute && authRoute.children) {
    childRoutes.value = authRoute.children;
}

const userdata = userdataStore();

let objUsername = {
    component: "InputText",
    label: langObject.username,
    errorLabels: ['Email jest wymagany', 'Email jest nieprawidłowy'],
    conditions: [{
        type: "required",
    },
    {
        type: "regex",
        value: "^\\w+([\\-\\.]\\w+)*@([\\w\\-]+\\.)+[\\w\\-]{2,4}$"
    },],
};

const InputPassword = ref();
let objPassword = {
    component: "Password",
    label: langObject.password,
    errorLabels: ['Hasło jest wymagane'],
    conditions: [{
        type: "required",
    },],
    innerInputProps: {
        feedback: false,
    }

};

const loginSubmit = async () => {
    let status = true;
    if (!password2.value.validate() || !username2.value.validate()) status = false;
    if (status) {
        let loadingTimeout;
        try {
            loadingTimeout = setTimeout(() => {
                isLoading.value = true;
            }, 400);

            const response = await fetch('/api/login', {
                body: JSON.stringify({
                    email: inputUsername.value,
                    password: InputPassword.value,
                }),
            });

            clearTimeout(loadingTimeout);

            if (response.ok) {
                const data = await response.json();

                if (data.success == 1) {
                    console.log("🚀 ~ loginSubmit ~ data:\x1B[34mutil.inspect(, false, 10, true)\x1B[0m", data)
                    userdata.username = data.result.name + " " + data.result.surname;
                    userdata.name = data.result.name;
                    userdata.surname = data.result.surname;
                    userdata.email = data.result.email;
                    userdata.defaultOrg = data.result.defaultOrg;
                    userdata.firstLogin = data.result.firstLogin;

                    router.push('/');
                    isLoading.value = false;
                }
                else if (data.success == 2) {
                    showToast('error', 'Błąd', langObject.error_wrongPasswordOrEmail);
                }
                else if (data.success == 3) {
                    showToast('error', 'Błąd', langObject.error_tooManyRequests);
                }
                else {
                    throw "error"
                }
            } else {
                showToast('error', 'Błąd', langObject.error_unknown);
            }
        } catch (error) {
            showToast('error', 'Błąd', langObject.error_unknown);
        } finally {
            clearTimeout(loadingTimeout);
            isLoading.value = false;
        }
    }
}  
</script>