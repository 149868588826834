<template>
    <div class="flex w-full h-full">
        <div class="flex flex-col w-full min-h-screen h-screen">
            <div class="w-full bg-white shadow z-10 sticky top-0 h-16 ">
                <RouterLink class="w-full h-full flex justify-between items-center" to="/">
                    <div
                        class="h-full flex gap-3 items-center px-5 text-xl font-semibold text-gray-500 dark:text-white">
                        <img class="w-8 h-8" src="/logo.png" alt="logo" />

                        <div class="flex flex-col">
                            <span>Upadłość Konsumencka - Kancelaria AR</span>
                        </div>
                    </div>
                    <div class="px-5 flex gap-5">
                        <RouterLink class="text-primary-500 text-nowrap" to="/">Strona główna</RouterLink>
                        <RouterLink class="text-primary-500 text-nowrap" to="/login">Zaloguj się</RouterLink>
                    </div>
                </RouterLink>
            </div>
            <!-- Wrapper to push the footer to the bottom -->
            <div class="flex flex-col flex-1 justify-between overflow-auto">
                <!-- for some fucking reason this div is needed -->
                <div>
                    <Policy />
                </div>
                <!-- Footer section -->
                <div class="flex w-full p-3 text-[0.75rem] text-surface-300"
                    :class="{ 'flex-col gap-16 justify-end items-center': isMobile(), 'justify-center items-end h-screen': !isMobile(), 'h-[20vh]': isIPhone(), 'h-[15vh]': !isIPhone() && isMobile()}">
                    <span>&copy;{{ new Date().getFullYear() }} &bull; Invictus. All rights reserved.</span>
                    <span v-if="isMobile()" style="color: rgb(249 250 251);">lorem</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { inject } from 'vue';
import Policy from '@/pages/policy.vue';

const isMobile = inject('isMobile');
const isIPhone = inject('isIPhone');
</script>