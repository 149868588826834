<template>
    <div class="flex flex-col gap-10">
        <ol class="list-decimal list-outside pl-4 text-justify">
            <li>Postanowienia ogólne</li>
            <li>Cel i zakres zbierania danych</li>
            <li>Podstawa przetwarzania danych</li>
            <li>Prawo kontroli, dostępu do treści swoich danych oraz ich poprawiania</li>
            <li>Pliki „Cookies”</li>
            <li>Postanowienia końcowe</li>
        </ol>

        <ol class="list-decimal list-outside pl-4 text-justify  flex flex-col gap-10">
            <li class="font-bold">
                <span>POSTANOWIENIA OGÓLNE</span>
                <ol class="list-decimal list-outside pl-4 text-justify font-normal flex flex-col gap-3">
                    <li>Administratorem danych osobowych zbieranych za pośrednictwem strony internetowej
                        https://upadlosc-ar-expert.pl jest <i>Upadłość Konsumencka - Kancelaria Adam Rogalski</i> z
                        siedzibą ul. Nadbrzeżna 2, 43-300 Bielsko-Biała (NIP 4990051434)
                        zwaną dalej „Administratorem”, będąca jednocześnie Usługodawcą.
                        <br>
                        adres do doręczeń: jak wyżej,<br>
                        adres poczty elektronicznej: biuro.arkancelaria@gmail.com
                    </li>
                    <li>
                        Dane osobowe Usługobiorcy są przetwarzane zgodnie z ustawą o ochronie danych osobowych z dnia 29
                        sierpnia
                        1997 r. (Dz. U. Nr 133, poz. 883 ze zm.) oraz ustawą o świadczeniu usług drogą elektroniczną z
                        dnia 18 lipca
                        2002 r. (Dz. U. Nr 144, poz. 1204 ze zm.).
                    </li>
                    <li>
                        Administrator dokłada szczególnej staranności w celu ochrony interesów osób, których dane
                        dotyczą, a w
                        szczególności zapewnia, że zbierane przez niego dane są:
                        przetwarzane zgodnie z prawem,
                        zbierane dla oznaczonych, zgodnych z prawem celów i niepoddawane dalszemu przetwarzaniu
                        niezgodnemu z tymi
                        celami,
                        merytorycznie poprawne i adekwatne w stosunku do celów, w jakich są przetwarzane oraz
                        przechowywane w postaci
                        umożliwiającej identyfikację osób, których dotyczą, nie dłużej niż jest to niezbędne do
                        osiągnięcia celu
                        przetwarzania.
                    </li>
                </ol>


            </li>
            <li class="font-bold">
                <span>CEL I ZAKRES ZBIERANIA DANYCH</span>
                <ol class="list-decimal list-outside pl-4 text-justify font-normal flex flex-col gap-3">
                    <li>
                        Dane osobowe Usługobiorców, zbierane przez Administratora, są wykorzystywane do kontaktowania
                        się z usługobiorcą (klientem) oraz świadczenia przez Administratora usług związanych z
                        przygotowaniem wniosku o upadłość konsumencką.
                    </li>
                    <li>Administrator przetwarza następujące dane osobowe Usługobiorców:
                        <ul class="list-disc list-outside pl-4 text-justify font-normal">
                            <li>Imię i nazwisko</li>
                            <li>Adres e-mail</li>
                            <li>Numer telefonu</li>
                            <li>Numer pesel</li>
                            <li>Adres zamieszakania i doręczeń</li>
                            <li>Spis: majątku, należności, wierzycieli, wierzytelności, źródeł przychodu i kosztów życia
                            </li>
                            <li>Informacje o czynnościach prawnych dokonanych przez usługobiorcę w ostatnich dwunastu
                                miesiącach,
                                których przedmiotem były nieruchomości, akcje lub udziały w spółkach, a także ruchmości,
                                wierzytelności lub inne prawa, których wartość przekracza 10 000zł.</li>
                            <li>Dodatkowe informacje w postaci opisowej dotyczące ogólnej życiowej sytuacji usługobiorcy
                            </li>
                            <li>Informację o posiadanych dokumentach (ich nazwy i daty) dotyczących potwierdzenia wyżej
                                wymienionych danych</li>

                        </ul>
                    </li>
                    <li>Administrator może przetwarzać następujące dane charakteryzujące sposób korzystania przez
                        Usługobiorcę z usług świadczonych drogą elektroniczną (dane eksploatacyjne):
                        <ul class="list-disc list-outside pl-4 text-justify font-normal">
                            <li>Oznaczenia identyfikujące zakończenie sieci telekomunikacyjnej lub system
                                teleinformatyczny, z którego korzystał Usługobiorca.
                            </li>
                            <li>Informacje o rozpoczęciu, zakończeniu oraz zakresie każdorazowego korzystania przez
                                Usługobiorcę z usługi świadczonej drogą elektroniczną.
                            </li>
                            <li>Informacje o skorzystaniu przez Usługobiorcę z usług świadczonych drogą elektroniczną.
                            </li>
                        </ul>
                    </li>
                    <li>Podanie danych osobowych, o których mowa w pkt. 2.2 jest niezbędne do świadczenia przez
                        Usługodawcę usług
                        drogą elektroniczną w ramach strony internetowej.</li>
                </ol>
            </li>
            <li class="font-bold">
                <span>PODSTAWA PRZETWARZANIA DANYCH</span>
                <ol class="list-decimal list-outside pl-4 text-justify font-normal flex flex-col gap-3">
                    <li>Korzystanie ze strony oraz zawieranie umów o świadczenie usług drogą elektroniczną za jej
                        pośrednictwem, z czym wiąże się konieczność podania danych osobowych, jest całkowicie
                        dobrowolne. Osoba, której dane dotyczą, samodzielnie decyduje czy chce rozpocząć korzystanie z
                        usług świadczonych drogą elektroniczną przez Usługodawcę zgodnie z Regulaminem.
                    </li>
                    <li>Zgodnie z art. 23 ustawy o ochronie danych osobowych z dnia 29 sierpnia 1997 r. (Dz. U. Nr 133,
                        poz. 883 ze zm.) przetwarzanie danych jest dopuszczalne m.in., gdy:

                        <ul class="list-disc list-outside pl-4 text-justify font-normal">
                            <li>osoba, której dane dotyczą, wyrazi na to zgodę, chyba że chodzi o usunięcie dotyczących
                                jej danych</li>
                            <li>jest to konieczne do realizacji umowy, gdy osoba, której dane dotyczą, jest jej stroną
                                lub gdy jest to niezbędne do podjęcia działań przed zawarciem umowy na żądanie osoby,
                                której dane dotyczą</li>
                        </ul>
                    </li>
                    <li>Przetwarzanie danych osobowych przez Administratora odbywa się zawsze w ramach podstaw
                        dopuszczalności ich przetwarzania wymienionych w pkt. 1.2 oraz 3.2. Przetwarzanie danych będzie
                        miało
                        związek z realizacją umowy lub konieczności podjęcia działań przed zawarciem umowy na żądanie
                        osoby, której dane dotyczą (pkt. 2.1).
                    </li>
                </ol>

            </li>
            <li class="font-bold">
                <span>PRAWO KONTROLI, DOSTĘPU DO TREŚCI SWOICH DANYCH I ICH POPRAWIANIA</span>
                <ol class="list-decimal list-outside pl-4 text-justify font-normal flex flex-col gap-3">
                    <li>Usługobiorca ma prawo dostępu do treści swoich danych osobowych oraz ich poprawiania.</li>
                    <li>Usługobiorcy przysługuje prawo do kontroli przetwarzania danych, których go dotyczą, zawartych w
                        zbiorze danych Administratora, a zwłaszcza prawo do żądania uzupełnienia, uaktualnienia,
                        sprostowania danych osobowych, czasowego lub stałego wstrzymania ich przetwarzania lub ich
                        usunięcia, jeżeli są one niekompletne, nieaktualne, nieprawdziwe lub zostały zebrane z
                        naruszeniem ustawy albo są już zbędne do realizacji celu, dla którego zostały zebrane.</li>
                    <li>W celu realizacji uprawnień, o których mowa w pkt. 4.1 i 4.2 można skorzystać poprzez przesłanie
                        stosownej wiadomości e-mail na adres: biuro.arkancelaria@gmail.com lub po zalogowaniu zgłosić
                        chęć
                        skorzystania z uprawnień przez formularz kontaktowy.</li>
                </ol>
            </li>
            <li class="font-bold">
                <span>PLIKI „COOKIES”</span>
                <ol class="list-decimal list-outside pl-4 text-justify font-normal flex flex-col gap-3">
                    <li>Strona internetowa Usługodawcy używa plików cookies. Brak zmiany po stronie Usługobiorcy
                        ustawień przeglądarki jest równoznaczny z wyrażeniem zgody na ich użycie.</li>
                    <li>Instalacja plików cookies jest konieczna do prawidłowego świadczenia usług na stronie
                        internetowej. W plikach cookies znajdują się informacje niezbędne do prawidłowego funkcjonowania
                        strony internetowej, w szczególności tych wymagających autoryzacji.</li>
                    <li>W ramach strony internetowej stosowane są następujące rodzaje plików cookies:
                        <ul class="list-disc list-outside pl-4 text-justify font-normal">
                            <li>sesyjne</li>
                            <li>stałe</li>
                        </ul>
                    </li>
                    <li>Cookies sesyjne są plikami tymczasowymi, które przechowywane są w urządzeniu końcowym
                        Usługobiorcy do czasu wylogowania (opuszczenia strony internetowej).</li>
                    <li>Stałe pliki cookies przechowywane są w urządzeniu końcowym Usługobiorcy przez czas określony w
                        parametrach plików cookies lub do czasu ich usunięcia przez Użytkownika.
                    </li>
                </ol>
            </li>
            <li class="font-bold">
                <span>POSTANOWIENIA KOŃCOWE</span>
                <br>
                <span class="text-justify font-normal">
                    Administrator stosuje środki techniczne i organizacyjne zapewniające ochronę przetwarzanych
                    danych osobowych odpowiednią do zagrożeń oraz kategorii danych objętych ochroną, a w szczególności
                    zabezpiecza dane przed ich udostępnieniem osobom nieupoważnionym, zabraniem przez osobę
                    nieuprawnioną, przetwarzaniem z naruszeniem obowiązujących przepisów oraz zmianą, utratą,
                    uszkodzeniem lub zniszczeniem. Usługodawca udostępnia odpowiednie środki techniczne zapobiegające
                    pozyskiwaniu i modyfikowaniu przez osoby nieuprawnione, danych osobowych przesyłanych drogą
                    elektroniczną.
                </span>
            </li>
        </ol>
    </div>
</template>

<script setup>
</script>